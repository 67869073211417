import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { useSelector } from "react-redux";

const writeEveryoneConfig = (workspaceId, update, batch = writeBatch(db)) => {
    const configRef = doc(db, "workspaces", workspaceId, "config", "workspaceConfig");
    batch.set(configRef, update, { merge: true });
    return batch;
};

export function useWorkspace() {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    async function updateRoleLimit(newRoleLimit) {
        try {
            const batch = writeEveryoneConfig(workspaceId, { roleLimit: newRoleLimit });
            await batch.commit();
        } catch (error) {
            console.error("Error updating role limit: ", error);
        }
    }

    async function flipFeatures(updated) {
        try {
            const batch = writeEveryoneConfig(workspaceId, { featureFlips: updated });
            await batch.commit();
        } catch (error) {
            console.error("Error flipping features: ", error);
        }
    }

    async function flipModules(newSubscribed, newEnabled) {
        const update = { subscribedModules: newSubscribed, enabledModules: newEnabled };
        try {
            const batch = writeEveryoneConfig(workspaceId, update);
            await batch.commit();
        } catch (error) {
            console.error("Error flipping modules: ", error);
        }
    }

    return { updateRoleLimit, flipFeatures, flipModules };
}
