function createObjectivesSnapshot(update, snapshot) {
    const { objectives } = update;

    if (!objectives) return snapshot;
    snapshot.obj = objectives;

    return snapshot;
}

function createRatingsSnapshot(update, snapshot) {
    const { ratings } = update;

    // Only update the snapshot this is a manager entry
    if (!ratings) return snapshot;

    // Remove any ratings that are 0
    Object.entries(ratings).forEach(([key, value]) => {
        if (!value) {
            delete ratings[key];
        }
    });
    snapshot.ratings = ratings;
    return snapshot;
}

function createFlagsSnapshot(update, snapshot) {
    const { flags } = update;

    // Only update the snapshot if this is a manager entry
    if (!flags) return snapshot;

    snapshot.flags = flags;
    return snapshot;
}

function createSnapshot(talentAssessment) {
    let snapshot = {};
    if (!talentAssessment) return {};
    snapshot = createObjectivesSnapshot(talentAssessment, snapshot);
    snapshot = createRatingsSnapshot(talentAssessment, snapshot);
    snapshot = createFlagsSnapshot(talentAssessment, snapshot);
    return snapshot;
}

export { createSnapshot };