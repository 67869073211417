// LoginForm.js
import React, { useState } from "react";
import { useSessionContext } from "../../../Session/Session";
import { projectId, usingEmulators } from "../../../../firebase/firebase";

const SignInTemplate = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const { onSignIn } = useSessionContext();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            onSignIn(email, password, setError);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="absolute h-screen w-screen flex items-center justify-center bg-gray-100">
            <form onSubmit={handleLogin} className="bg-white p-8 rounded-lg shadow-lg w-96 space-y-4">
                <div className="text-center space-y-1">
                    <h2 className="text-2xl font-bold mb-6 text-center text-primary">Sign In</h2>
                    <div className="p-2 border rounded-lg">
                        <p>
                            <span>{projectId}</span>
                        </p>
                        <div className="font-bold">
                            {usingEmulators ? <span>Emulators</span> : <span className="text-error">Live</span>}
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-secondary mb-2" htmlFor="email">
                        Email:
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="input input-bordered w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-secondary mb-2" htmlFor="password">
                        Password:
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="input input-bordered w-full"
                    />
                </div>
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                <button type="submit" className="btn btn-primary w-full">
                    Login
                </button>
            </form>
        </div>
    );
};

export default SignInTemplate;
