import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../../../common/components/organisms/Modal/Modal";
import Select from "../../../common/components/atoms/Select/Select";

const MigrateToWorkspaceModal = ({ isOpen, disabled, onSubmit, onToggle }) => {
    const [workspaceId, setWorkspaceId] = useState();
    const workspaces = useSelector((state) => state.app.workspaces);

    const workspaceOptions = useMemo(() => {
        return workspaces.map((workspace) => ({
            id: workspace.id,
            label: workspace.name,
            value: workspace.id,
        }));
    }, [workspaces]);

    const handleSubmit = () => {
        onSubmit(workspaceId);
    };

    const handleSetTargetWorkspace = (id) => {
        setWorkspaceId(id);
    };

    return (
        <Modal
            title="Migrate To Workspace"
            isOpen={isOpen}
            isValid={!!workspaceId}
            onToggle={onToggle}
            onSubmit={handleSubmit}
            submitting={disabled}
            size="md"
        >
            <div className="flex flex-col space-y-4 text-sm">
                <Select
                    disabled={disabled}
                    placeholder="Select Target Workspace"
                    options={workspaceOptions}
                    selectedIds={workspaceId}
                    onChange={handleSetTargetWorkspace}
                />
            </div>
        </Modal>
    );
};

export default MigrateToWorkspaceModal;
