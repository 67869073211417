import classNames from "classnames";
import React from "react";
import { confirmFirst } from "../../../../common/utils/confirmFirst";
import WorkspaceConfig from "../organisms/WorkspaceConfig";
import Loading from "../../../../common/components/atoms/Loading/Loading";

const HomePageTemplate = ({
    workspaces = [],
    activeWorkspaceId,
    workspaceStatus,
    activeWorkspace = {},
    workspaceConfig = {},
    onSetActiveWorkspace,
}) => {
    const { workspaceName } = activeWorkspace;
    const handleSetActiveWorkspace = (workspaceId) => {
        if (workspaceId === activeWorkspaceId) return;
        confirmFirst({
            title: "Switch Workspace",
            message: "Are you sure you want to switch workspaces?",
            onConfirm: () => onSetActiveWorkspace(workspaceId),
        });
    };
    const workspaceReady = workspaceStatus === "success";

    const renderEmpty = () => {
        const loading = workspaceStatus !== "success" && activeWorkspaceId;
        return (
            <div className="flex-1 flex justify-center items-center">
                {loading ? <Loading /> : <span>No Workspace Selected</span>}
            </div>
        );
    };

    return (
        <div className="flex-1 flex space-x-6 items-stretch overflow-auto">
            <div className="flex-1 flex flex-col bg-white rounded-lg p-6 space-y-2 overflow-auto">
                <h6 className="border-b">Workspaces</h6>
                <ul className="menu overflow-scroll flex-1 divide-y">
                    {workspaces.map((workspace) => {
                        const selected = workspace.id === activeWorkspaceId;
                        return (
                            <li
                                className={classNames("rounded-lg", { "bg-primary text-white": selected })}
                                key={workspace.id}
                                onClick={() => handleSetActiveWorkspace(workspace.id)}
                            >
                                <span>{workspace.name}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="flex flex-col flex-3 rounded-lg bg-white p-6">
                {workspaceReady && <WorkspaceConfig activeWorkspaceId={activeWorkspaceId} name={workspaceName} workspaceConfig={workspaceConfig} />}
                {!workspaceReady && renderEmpty()}
            </div>
        </div>
    );
};

export default HomePageTemplate;
