import React, { useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../../features/Session/Session";
import SignInTemplate from "../../features/SignIn/components/templates/SignInTemplate";

const SignInPage = () => {
    const navigate = useNavigate();
    const { uid, onSignIn } = useSessionContext();

    useEffect(() => {
        if (!!uid) {
            navigate("/home");
        }
    }, [navigate, uid]);

    return (
        <div className="page page-padding">
            <SignInTemplate onSignIn={onSignIn} />
        </div>
    );
};

export default SignInPage;
