import { getUniqueId } from "../../../common/utils/getUniqueId";
import { removeNullAndUndefined } from "../../../common/utils/objectUtils";
import { DEFAULT_BUSINESS_UNIT_ID } from "../../../constants";
import { filterEnabled } from "./filterEnabled";
import { getLatestAssessment, migrateAssessments } from "./migrateAssessments";
import { migrateSnapshots } from "./migrateSnapshots";

function buildParentMap(roles) {
    let parentMap = {};
    for (let role of roles) {
        if (role.parentRoleId) {
            parentMap[role.id] = role.parentRoleId;
        }
    }
    return parentMap;
}

function migrateEmployees(employees, parentMap) {
    let migrated = {};
    let unitDocId = "fuynke"; // standard first employee unitDocId
    let unitDocIdUsageCount = 0;
    const UNIT_DOC_ID_LIMIT = 200;
    for (let employee of employees) {
        const {
            id,
            canCreateAdmins,
            inviteAccepted,
            pending,
            uid,
            timeline,
            emailInUse,
            talentBoardIds,
            inviteCounter,
            talentAssessment,
            selfAssessment,
            publicInfoDocId,
            ...rest
        } = employee;
        const parentRoleId = parentMap[employee.roleId] || null;
        const customClaims = canCreateAdmins ? { canCreateAdmins: true } : {};

        // Check if the current unitDocId has reached its usage limit
        if (unitDocIdUsageCount >= UNIT_DOC_ID_LIMIT) {
            unitDocId = getUniqueId(); // Generate a new unitDocId
            unitDocIdUsageCount = 0; // Reset the usage count
        }

        const newEmployee = {
            ...rest,
            parentRoleId,
            customClaims,
            businessUnitId: DEFAULT_BUSINESS_UNIT_ID,
            unitDocId,
        };
        if (newEmployee.isUser) newEmployee.inviteAccepted = false;
        removeNullAndUndefined(newEmployee);
        migrated[employee.id] = newEmployee;
        unitDocIdUsageCount++;
    }
    return migrated;
}

function migrateRoles(roles, employees, legacyAssessments) {
    let migrated = {};
    let unitDocId = "peBn15"; // standard first role unitDocId
    let unitDocIdUsageCount = 0;
    const UNIT_DOC_ID_LIMIT = 300;
    const enabledEmployeeIds = employees.map((employee) => employee.id);

    Object.values(roles).forEach((role) => {
        const {
            id,
            incumbentId,
            childRoles,
            noAutoAssignManagement,
            forceCascadeCount,
            authRoles,
            departmentName,
            publicInfoDocId,
            talentBoardIds,
            ...rest
        } = role;
        let successorIds = [];
        let successorCount = 0;
        if (incumbentId) {
            const latestAssessment = getLatestAssessment(legacyAssessments, role.incumbentId);
            if (latestAssessment?.f3) {
                successorIds = latestAssessment.f3;
                successorIds = successorIds.filter((id) => enabledEmployeeIds.includes(id));
                successorCount = successorIds.length;
            }
        }
        // Check if the current unitDocId has reached its usage limit
        if (unitDocIdUsageCount >= UNIT_DOC_ID_LIMIT) {
            console.log("unitDocIdUsageCount", unitDocIdUsageCount);
            unitDocId = getUniqueId(); // Generate a new unitDocId
            unitDocIdUsageCount = 0; // Reset the usage count
        }
        let newRole = {
            ...rest,
            successorIds,
            unitDocId,
            businessUnitId: DEFAULT_BUSINESS_UNIT_ID,
        };
        if (successorCount > 0) newRole.successorCount = successorCount;
        
        const isValidEmployee = incumbentId && enabledEmployeeIds.includes(incumbentId);
        if (isValidEmployee) {
            newRole.incumbentId = incumbentId;
        }
        removeNullAndUndefined(newRole);
        migrated[role.id] = newRole;
        unitDocIdUsageCount++;
    });
    return migrated;
}

function buildBoardMap(roles) {
    let boardMap = {};
    for (let role of roles) {
        if (role.talentBoardIds) {
            role.talentBoardIds.forEach((boardId) => {
                if (!boardMap[boardId]) {
                    boardMap[boardId] = [];
                }
                boardMap[boardId].push(role.id);
            });
        }
    }
    return boardMap;
}

function buildActionTracking(employees, actions) {
    const enabledActions = filterEnabled(actions);
    function helper_addToUnitDoc(tracking, id, key) {
        const employee = employees[id];
        const unitDocId = employee?.unitDocId;
        if (unitDocId) {
            const currentUnitDoc = tracking[unitDocId] || {};
            const currentTracking = currentUnitDoc[key] || [];
            currentTracking.push(id);
            currentUnitDoc[key] = currentTracking;
            tracking[unitDocId] = currentUnitDoc;
        }
    }

    let allTracking = {};
    for (let action of enabledActions) {
        const tracking = {};
        const { scheduledEmployeeIds = [], completedEmployeeIds = [] } = action;
        for (let id of scheduledEmployeeIds) {
            helper_addToUnitDoc(tracking, id, "scheduledEmployeeIds");
        }
        for (let id of completedEmployeeIds) {
            helper_addToUnitDoc(tracking, id, "completedEmployeeIds");
        }
        allTracking[action.id] = tracking;
    }
    return allTracking;
}

export function migrateOrg(businessUnit, actions) {
    const { employees, roles, employeeAssessments } = businessUnit;
    const validAssessments = Object.values(employeeAssessments).filter((assessment) => !assessment.skipped);
    const enabledRoles = filterEnabled(roles);
    const enabledEmployees = filterEnabled(employees);

    // Build parentMap
    const parentMap = buildParentMap(enabledRoles);

    // Create employees
    const migratedEmployees = migrateEmployees(enabledEmployees, parentMap);

    // Create roles
    const migratedRoles = migrateRoles(enabledRoles, enabledEmployees, validAssessments);

    // Create assessments
    const assessments = migrateAssessments(businessUnit, migratedEmployees);

    // Build snapshots
    const { businessUnitSnapshots, roleSnapshots } = migrateSnapshots(
        assessments,
        migratedRoles,
        migratedEmployees,
        parentMap
    );

    // build boardMap
    const boardMap = buildBoardMap(enabledRoles);

    // build action tracking based on updated employees
    const actionTracking = buildActionTracking(migratedEmployees, actions);

    return {
        ...businessUnit,
        employees: migratedEmployees,
        roles: migratedRoles,
        assessments,
        businessUnitSnapshots,
        roleSnapshots,
        parentMap,
        boardMap,
        actionTracking,
    };
}
