import React, { useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Loading from "./common/components/atoms/Loading/Loading";
import { usePathArray } from "./common/hooks/usePathArray";
import AppLayout from "./containers/AppLayout";
import { useSessionContext } from "./features/Session/Session";
import ExportPage from "./pages/export/ExportPage";
import HomePage from "./pages/home/HomePage";
import ImportPage from "./pages/import/ImportPage";
import MigratePage from "./pages/migrate/MigratePage";
import ScriptsPage from "./pages/scripts/ScriptsPage";
import UsersPage from "./pages/users/UsersPage";
import MimicPage from "./pages/mimic/MimicPage";

const App = () => {
    const navigate = useNavigate();
    const { authHasLoaded, uid, activeWorkspaceId, checkDisabled } = useSessionContext();
    const pathArray = usePathArray();

    useEffect(() => {
        const lastPathSegment = pathArray[pathArray.length - 1];
        if (authHasLoaded && !uid) {
            navigate("/sign-in");
        }

        // Navigate home when no workspace is selected and one is needed
        const onHomePage = lastPathSegment === "home";
        const needsRedirect = checkDisabled(lastPathSegment);
        if (!onHomePage && needsRedirect && authHasLoaded && !!uid) {
            navigate("/home");
        }
    }, [navigate, pathArray, authHasLoaded, uid, activeWorkspaceId]);

    if (!authHasLoaded) return <Loading />;
    return (
        <Routes>
            <Route path="/" element={<AppLayout />}>
                <Route index element={<Navigate to="home" />} />
                <Route path="mimic" element={<MimicPage />} />
                <Route path="home" element={<HomePage />} />
                <Route path="export" element={<ExportPage />} />
                <Route path="import" element={<ImportPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="scripts" element={<ScriptsPage />} />
                <Route path="migrate" element={<MigratePage />} />
            </Route>
        </Routes>
    );
};

export default App;
