import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { useListener } from "../../../firebase/hooks/useListener";
import { getDocRef, getSubCollectionRef, getWorkspacesCollectionRef } from "../../../firebase/refs/firebaseRefs";
import { RESET as RESET_WORKSPACE, SET_WORKSPACE, SET_WORKSPACE_CONFIG } from "../../../redux/workspaceSlice";

export function useWorkspaceListener(workspaceId) {
    const dispatch = useDispatch();

    const workspaceRef = useMemo(() => {
        if (!workspaceId) return null;
        const workspacesRef = getWorkspacesCollectionRef();
        const workspaceRef = getDocRef(workspacesRef, workspaceId);
        return workspaceRef;
    }, [workspaceId]);

    const configRef = useMemo(() => {
        if (!workspaceRef) return null;
        const configCollectionRef = getSubCollectionRef(workspaceRef, "config");
        const configRef = getDocRef(configCollectionRef, "workspaceConfig");
        return configRef;
    }, [workspaceRef]);

    const shouldListen = useMemo(() => {
        return !!workspaceId;
    }, [workspaceId]);

    const handleWorkspaceDataReceived = useCallback(
        async (document) => {
            try {
                let prepped = ensureSerializable(document.data());
                dispatch(SET_WORKSPACE({ workspaceId: document.id, ...prepped }));
            } catch (error) {
                dispatch(RESET_WORKSPACE());
                throw new Error(`Error getting workspace data: ${error.message}`);
            }
        },
        [dispatch]
    );

    const handleConfigReceived = useCallback(
        async (document) => {
            try {
                let prepped = ensureSerializable(document.data());
                dispatch(SET_WORKSPACE_CONFIG(prepped));
            } catch (error) {
                throw new Error(`Error getting workspace config: ${error.message}`);
            }
        },
        [dispatch]
    );

    useListener(configRef, handleConfigReceived, shouldListen);
    return useListener(workspaceRef, handleWorkspaceDataReceived, shouldListen);
}
