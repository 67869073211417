import { createSnapshot } from "./createSnapshot";
import { filterEnabled } from "./filterEnabled";

function addToUnitDoc(unitDocs, unitDocId, employeeId, employeeSnapshot) {
    const currentUnitDoc = unitDocs[unitDocId] || {};
    currentUnitDoc[employeeId] = employeeSnapshot;
    unitDocs[unitDocId] = currentUnitDoc;
}

function getAllChildEmployees(parentMap, employees, roleId) {
    let allChildRoleIds = [];
    function helper_recursive_child_roles(roleId) {
        const childRoles = Object.entries(parentMap || {})
            .filter(([, parentRoleId]) => parentRoleId === roleId)
            .map(([childRoleId]) => childRoleId);
        allChildRoleIds.push(...childRoles);
        childRoles.forEach((childRoleId) => {
            helper_recursive_child_roles(childRoleId);
        });
    }

    // Get all the child roles for the
    helper_recursive_child_roles(roleId);

    // Return the employees that are under the role Id
    return allChildRoleIds
        .map((roleId) => {
            return employees.find((employee) => employee.roleId === roleId);
        })
        .filter(Boolean);
}

export function migrateSnapshots(assessments, roles, employees, parentMap) {
    const enabledRoles = filterEnabled(roles).filter((role) => !role.rootRole);
    const enabledEmployees = filterEnabled(employees);

    const businessUnitSnapshots = {};
    const roleSnapshots = {};

    for (let role of enabledRoles) {
        const { id: roleId, businessUnitId } = role;
        const businessUnitDocs = businessUnitSnapshots[businessUnitId] || {};
        const roleUnitDocs = {};
        const allChildEmployees = getAllChildEmployees(parentMap, enabledEmployees, roleId);
        for (let employee of allChildEmployees) {
            const { id: employeeId, unitDocId } = employee;
            const employeeAssessment = assessments[employeeId];
            if (Object.keys(employeeAssessment || {}).length > 0) {
                const snapshot = createSnapshot(employeeAssessment);
                // Add to business unit documents
                addToUnitDoc(businessUnitDocs, unitDocId, employeeId, snapshot);

                // Add to role documents
                addToUnitDoc(roleUnitDocs, unitDocId, employeeId, snapshot);
            }
        }
        if (Object.keys(businessUnitDocs).length > 0) {
            businessUnitSnapshots[businessUnitId] = businessUnitDocs;
        }
        if (Object.keys(roleUnitDocs).length > 0) {
            roleSnapshots[roleId] = roleUnitDocs;
        }
    }
    return { businessUnitSnapshots, roleSnapshots };
}
