import { useMemo } from "react";
import useEditValues from "../../../common/hooks/useEditValues";

export function useEditedWorkspace() {
    const [editedWorkspace, setValue] = useEditValues();

    function updateWorkspace(key, value) {
        switch (key) {
            default:
                setValue(key, value);
        }
    }

    const isValid = useMemo(() => {
        return !!editedWorkspace?.workspaceId && !!editedWorkspace?.workspaceName;
    }, [editedWorkspace]);

    return [editedWorkspace, updateWorkspace, isValid];
}
