import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../../common/components/atoms/Button/Button";
import Each from "../../../../common/components/molecules/Each/Each";
import InfiniteTable from "../../../../common/components/organisms/InfiniteTable/InfiniteTable";
import { exportJSONToFile } from "../../../../common/components/organisms/InfiniteTable/utils/exportJSONtoFile";
import Tabs from "../../../../common/components/organisms/Tabs/Tabs";
import { useToggle } from "../../../../common/hooks/useToggle";
import { getDocRef, getWorkspacesCollectionRef } from "../../../../firebase/refs/firebaseRefs";
import { usePreppedData } from "../../../Export/hooks/usePreppedData";
import { prepTableColumns } from "../../../Export/utils/prepTableColumns";
import MigrateToWorkspaceModal from "../../containers/MigrateToWorkspaceModal";
import { migrateConfig } from "../../utils/migrateConfig";
import { migrateOrg } from "../../utils/migrateOrg";
import { migrateTalent } from "../../utils/migrateTalent";
import { testMigration } from "../../utils/writeMigration";
import { toast } from "react-toastify";

const TAB_OPTIONS = [
    { id: "employees", label: "Employees" },
    { id: "roles", label: "Roles" },
    { id: "parentMap", label: "Parent Map" },
    { id: "boardMap", label: "Board Map" },
    { id: "assessments", label: "Assessments" },
    { id: "employeeAssessments", label: "Legacy Assessments" },
    { id: "assessmentSnapshots", label: "Assessment Snapshots" },
    { id: "talentAreas", label: "Talent Areas" },
    { id: "traits", label: "Traits" },
    { id: "talentBoards", label: "Talent Boards" },
    { id: "actions", label: "Actions" },
    { id: "traitMap", label: "Trait Map" },
    { id: "actionTracking", label: "Action Tracking" },
    { id: "assessmentSettings", label: "Assessment Settings" },
    { id: "customFields", label: "Custom Fields" },
    { id: "workspace", label: "Workspace" },
    { id: "missingEmails", label: "Missing Emails" },
    { id: "snapshotDocs", label: "Snapshots" },
];

const MIGRATE_OPTIONS = [
    {
        id: "migrateOrg",
        label: "Migrate Org",
        tabs: [
            "employees",
            "roles",
            "parentMap",
            "boardMap",
            "missingEmails",
            "assessments",
            "employeeAssessments",
            "snapshotDocs",
        ],
    },
    {
        id: "migrateTalent",
        label: "Migrate Talent",
        tabs: ["talentAreas", "traits", "talentBoards", "actions", "traitMap", "actionTracking"],
    },
    {
        id: "migrateConfig",
        label: "Migrate Config",
        tabs: ["assessmentSettings", "customFields", "workspace"],
    },
];

const MigratePageTemplate = ({ businessUnit, workspace, onFetch }) => {
    const [disableMigration, setDisableMigration] = useState(false);
    const [showMigrate, toggleMigrate] = useToggle();
    const [cleanedWorkspace, setCleanedWorkspace] = useState({});
    const [cleanedBusinessUnit, setCleanedBusinessUnit] = useState({});
    const [activePageId, setActivePageId] = useState();
    const [activeTabId, setActiveTabId] = useState();
    const activePage = MIGRATE_OPTIONS.find((o) => o.id === activePageId);
    const tableData = usePreppedData(activeTabId, cleanedWorkspace, cleanedBusinessUnit);

    useEffect(() => {
        setCleanedWorkspace(workspace || {});
    }, [workspace]);

    useEffect(() => {
        setCleanedBusinessUnit(businessUnit || {});
    }, [businessUnit]);

    const activeTabs = useMemo(() => {
        const { tabs = [] } = activePage || {};
        return tabs.map((tab) => TAB_OPTIONS.find((o) => o.id === tab));
    }, [activePage]);

    const columns = useMemo(() => {
        if (!tableData) return [];
        return prepTableColumns(tableData);
    }, [tableData]);

    const handleExportAll = () => {
        const { actions } = workspace;
        const migratedOrg = migrateOrg(businessUnit, actions);
        const migratedTalent = migrateTalent(workspace);
        const migratedConfig = migrateConfig(workspace);
        const fullMigration = {
            org: migratedOrg,
            talent: migratedTalent,
            config: migratedConfig,
        };
        const workspaceName = workspace?.workspace?.workspaceName;
        exportJSONToFile(fullMigration, workspaceName);
    };

    const handlePreview = () => {
        switch (activePageId) {
            case "migrateOrg":
                const { actions } = workspace;
                const migratedOrg = migrateOrg(businessUnit, actions);
                setCleanedBusinessUnit(migratedOrg);
                break;
            case "migrateTalent":
                const migratedTalent = migrateTalent(workspace);
                setCleanedWorkspace(migratedTalent);
                break;
            case "migrateConfig":
                const migratedConfig = migrateConfig(workspace);
                setCleanedWorkspace(migratedConfig);
                break;
            default:
                break;
        }
    };

    const handleMigrate = async (workspaceId) => {
        const workspacesRef = getWorkspacesCollectionRef();
        const workspaceRef = getDocRef(workspacesRef, workspaceId);
        try {
            setDisableMigration(true);
            toast.info("Migration Started");
            await testMigration(workspaceRef, businessUnit, workspace);
            toast.success("Migration Successful");
        } catch (error) {
            toast.error("Migration");
        } finally {
            toggleMigrate();
            setDisableMigration(false);
        }
    };

    const handleSetPage = (id) => {
        setActivePageId(id);
        const newPage = MIGRATE_OPTIONS.find((o) => o.id === id);
        const { tabs } = newPage || {};
        const firstTab = tabs[0];
        setActiveTabId(firstTab);
        onFetch();
    };

    return (
        <div className="flex-1 flex space-x-6 items-stretch overflow-auto">
            <div className="w-80 min-w-80 flex flex-col bg-white rounded-lg p-6 space-y-2 overflow-auto">
                <div className="menu p-0 overflow-scroll flex flex-col flex-1 divide-y">
                    <Each
                        of={MIGRATE_OPTIONS}
                        render={(option) => {
                            const selected = activePageId === option.id;
                            return (
                                <div
                                    onClick={() => handleSetPage(option.id)}
                                    className={classNames("p-2 cursor-pointer rounded hover:bg-base-200", {
                                        "bg-primary text-white": selected,
                                    })}
                                >
                                    {option.label}
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
            <div className="flex-3 flex flex-col rounded-lg bg-white p-6 overflow-x-hidden space-y-2">
                <div className="py-2 px-6 bg-base-200 rounded-lg flex items-center justify-between">
                    <h4>{activePage?.label || "No Active Page"}</h4>
                    <div className="space-x-2 flex items-center">
                        <Button onClick={onFetch} size="sm" color="primary">
                            Fetch Source Data
                        </Button>
                        <Button onClick={toggleMigrate} disabled={disableMigration} size="sm" color="primary">
                            Test Migration
                        </Button>
                        <Button onClick={handlePreview} size="sm" color="primary">
                            Preview
                        </Button>
                        <Button onClick={handleExportAll} size="sm" color="primary">
                            Export All
                        </Button>
                    </div>
                </div>
                <div className="flex-1 flex flex-col overflow-auto">
                    <Tabs tabs={activeTabs} tabKey={activeTabId} onChange={setActiveTabId} />
                    <div className="flex-1 flex flex-col overflow-auto">
                        <InfiniteTable tableId={activePageId} data={tableData} columns={columns} />
                    </div>
                </div>
            </div>
            <MigrateToWorkspaceModal isOpen={showMigrate} disabled={disableMigration} onToggle={toggleMigrate} onSubmit={handleMigrate} />
        </div>
    );
};

export default MigratePageTemplate;
