function toggleItemInArray(arr = [], item) {
    const set = new Set(arr);
    set.has(item) ? set.delete(item) : set.add(item);
    return Array.from(set);
}

function helper_coerceValues(aValue, bValue) {
    if (typeof aValue === "boolean" || typeof bValue === "boolean") {
        // Coerce undefined to false for boolean sorting
        aValue = aValue === undefined ? false : aValue;
        bValue = bValue === undefined ? false : bValue;
    } else if (typeof aValue === "string" || typeof bValue === "string") {
        // Coerce undefined to empty string for string sorting
        aValue = aValue === undefined ? "" : aValue;
        bValue = bValue === undefined ? "" : bValue;
    }
    if (typeof aValue === "number" || typeof bValue === "number") {
        // Coerce undefined to 0 for number sorting
        aValue = aValue === undefined ? 0 : aValue;
        bValue = bValue === undefined ? 0 : bValue;
    } else {
        aValue = aValue === undefined ? null : aValue;
        bValue = bValue === undefined ? null : bValue;
    }
    return { aValue, bValue };
}

function sortArrayOfObjects(sortThisArray, byThisField, sortOrder) {
    const sortDesc = sortOrder === "desc" || sortOrder === true;

    const sortedArray = sortThisArray.slice().sort((a, b) => {
        const { aValue, bValue } = helper_coerceValues(a[byThisField], b[byThisField]);

        // Sorting logic for strings
        if (typeof aValue === "string" && typeof bValue === "string") {
            return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        }
        // Sorting logic for numbers
        else if (typeof aValue === "number" && typeof bValue === "number") {
            return sortDesc ? bValue - aValue : aValue - bValue;
        }
        // Sorting logic for booleans
        else if (typeof aValue === "boolean" && typeof bValue === "boolean") {
            if (aValue === bValue) {
                return 0; // no change if both are true or both are false
            } else if (sortDesc) {
                return aValue ? -1 : 1; // true comes first if descending
            } else {
                return aValue ? 1 : -1; // false comes first if ascending
            }
        }
        // Fallback case if types are not consistent or not recognized
        else {
            // Data types don't match; treat the values as equal.
            return 0;
        }
    });
    return sortedArray;
}

const mergeArrays = (betaArray, alphaArray) => {
    const alphaIds = alphaArray.map((note) => note.id);
    const uneatenBeta = betaArray.filter((beta) => {
        return !alphaIds.includes(beta.id);
    });
    return [...alphaArray, ...uneatenBeta];
};

export { mergeArrays, sortArrayOfObjects, toggleItemInArray };
