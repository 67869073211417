import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../../atoms/Button/Button";
import classNames from "classnames";

const widthClasses = {
    sm: "max-w-lg",
    md: "max-w-2xl",
    lg: "max-w-5xl",
    xl: "max-w-5xl",
    fullscreen: "max-w-full",
};

const heightClasses = {
    sm: "max-h-4/12",
    md: "max-h-6/12",
    lg: "max-h-8/12",
    xl: "max-h-10/12",
    fullscreen: "max-h-11/12",
};

function getSizeClass(size, heightProp, widthProp) {
    const height = heightProp || size;
    const width = widthProp || size;
    return `${widthClasses[width]} ${heightClasses[height]}`;
}

const Modal = ({ isOpen, title, size = "md", height, width,submitting, onToggle, onSubmit, isValid, children }) => {
    if (!isOpen) return null;
    const sizeClass = getSizeClass(size, height, width);
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-base-800 bg-opacity-50">
            <div className={classNames("flex flex-col bg-white rounded-lg shadow-lg w-11/12 h-full", sizeClass)}>
                <div className="flex justify-between items-center p-4 border-b">
                    <h3 className="text-lg font-semibold">{title}</h3>
                    <button onClick={onToggle}>
                        <AiOutlineClose className="text-xl" />
                    </button>
                </div>
                <div className="flex-1 flex flex-col">
                    <div className="p-4 flex-1">{children}</div>
                    <div className="flex justify-end p-4 border-t">
                        <Button loading={submitting} disabled={!isValid || submitting} onClick={onSubmit}>
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
