import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workspaceId: null,
    workspace: {},
    workspaceConfig: {
        featureFlips: {},
        enabledModules: [],
        SubscribedModules: [],
    },
    activeBusinessUnitId: {},
    talentAreas: {},
    traits: {},
    talentBoards: {},
    actions: {},
    traitMap: {},
};

const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        SET_WORKSPACE(state, action) {
            state.workspace = action.payload;
            state.workspaceId = action.payload?.workspaceId || null;
        },
        SET_WORKSPACE_CONFIG(state, action) {
            state.workspaceConfig = action.payload;
        },
        SET_ACTIVE_BUSINESS_UNIT_ID(state, action) {
            state.activeBusinessUnitId = action.payload;
        },
        SET_TALENT_AREAS(state, action) {
            state.talentAreas = action.payload;
        },
        SET_TRAITS(state, action) {
            state.traits = action.payload;
        },
        SET_TALENT_BOARDS(state, action) {
            state.talentBoards = action.payload;
        },
        SET_ACTIONS(state, action) {
            state.actions = action.payload;
        },
        SET_TRAIT_MAP(state, action) {
            state.traitMap = action.payload;
        },
        RESET: () => initialState,
    },
});

const { actions, reducer } = workspaceSlice;

export const {
    SET_WORKSPACE,
    SET_WORKSPACE_CONFIG,
    SET_ACTIVE_BUSINESS_UNIT_ID,
    SET_TALENT_AREAS,
    SET_TRAITS,
    SET_TALENT_BOARDS,
    SET_ACTIONS,
    SET_TRAIT_MAP,
    RESET,
} = actions;

export default reducer;
