import FeatureFlipping from "./FeatureFlipping";
import RoleLimits from "./RoleLimits";

const WorkspaceConfig = ({ activeWorkspaceId, name, workspaceConfig }) => {
    return (
        <>
            <h2>{name}</h2>
            <hr />
            <div className="divide-y">
                <div className="flex py-4 space-x-6">
                    <h4>Workspace Id</h4>
                    <h4>{activeWorkspaceId} </h4>
                </div>
                <div className="py-4">
                    <RoleLimits workspaceConfig={workspaceConfig} />
                </div>
                <div className="py-4">
                    <FeatureFlipping workspaceConfig={workspaceConfig} />
                </div>
            </div>
        </>
    );
};

export default WorkspaceConfig;
