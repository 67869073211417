import React, { useMemo, useState } from "react";
import Button from "../../../../common/components/atoms/Button/Button";
import Checkbox from "../../../../common/components/atoms/Checkbox/Checkbox";
import CheckOrSelect from "../../../../common/components/atoms/CheckOrSelect/CheckOrSelect";
import Each from "../../../../common/components/molecules/Each/Each";
import { exportJSONToFile } from "../../../../common/components/organisms/InfiniteTable/utils/exportJSONtoFile";
import { useToggledList } from "../../../../common/hooks/useToggledList";
import { usePreppedData } from "../../hooks/usePreppedData";
import { prepTableColumns } from "../../utils/prepTableColumns";
import InfiniteTable from "../../../../common/components/organisms/InfiniteTable/InfiniteTable";

const EXPORT_OPTIONS = [
    { id: "employees", label: "Employees" },
    { id: "roles", label: "Roles" },
    { id: "talentAreas", label: "Talent Areas" },
    { id: "traits", label: "Traits" },
    { id: "talentBoards", label: "Talent Boards" },
    { id: "actions", label: "Actions" },
    { id: "traitMap", label: "Trait Map" },
    { id: "assessments", label: "Assessments" },
    { id: "employeeAssessments", label: "Legacy Assessments" },
    { id: "objectives", label: "Objectives" },
    { id: "boardMap", label: "Board Map" },
];

const ExportPageTemplate = ({ businessUnit, workspace, onFetch }) => {
    const [activePageId, setActivePageId] = useState(null);
    const activePage = EXPORT_OPTIONS.find((o) => o.id === activePageId);
    const [checkedIds, toggleChecked, toggleAll, allChecked] = useToggledList(EXPORT_OPTIONS.map((o) => o.id));
    const tableData = usePreppedData(activePageId, workspace, businessUnit);

    const columns = useMemo(() => {
        if (!tableData) return [];
        return prepTableColumns(tableData);
    }, [tableData]);

    const handleExport = () => {
        exportJSONToFile(tableData, activePageId);
    };

    const handleFetch = () => {
        onFetch(checkedIds);
    };

    return (
        <div className="flex-1 flex space-x-6 items-stretch overflow-auto">
            <div className="w-80 min-w-80 flex flex-col bg-white rounded-lg p-6 space-y-2 overflow-auto">
                <div className="py-2 px-6 bg-base-200 rounded-lg border-b flex items-center justify-between">
                    <h4>Options</h4>
                    <div className="space-x-2 flex items-center">
                        <Button color="primary" onClick={handleFetch} size="sm">
                            Fetch Checked
                        </Button>
                    </div>
                </div>
                <div className="menu p-0 overflow-scroll flex flex-col flex-1 divide-y">
                    <div className="flex items-center p-1 bg-blue-100">
                        <div className="w-12">
                            <Checkbox color="primary" checked={allChecked} onChange={toggleAll} />
                        </div>
                        <div className="flex-1">
                            <h6 className="uppercase font-semibold tracking-tight">Page</h6>
                        </div>
                    </div>
                    <Each
                        of={EXPORT_OPTIONS}
                        render={(option) => {
                            const selected = activePageId === option.id;
                            const checked = checkedIds.includes(option.id);
                            return (
                                <CheckOrSelect
                                    option={option}
                                    checked={checked}
                                    selected={selected}
                                    onSelect={setActivePageId}
                                    onCheck={toggleChecked}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className="flex-3 flex flex-col rounded-lg bg-white p-6 overflow-x-hidden space-y-2">
                <div className="py-2 px-6 bg-base-200 rounded-lg flex items-center justify-between">
                    <h4>{activePage?.label || "No Active Page"}</h4>
                    <div className="space-x-2 flex items-center">
                        <Button onClick={handleExport} size="sm" color="neutral">
                            Export Page
                        </Button>
                    </div>
                </div>
                <div className="flex-1 flex flex-col">
                    <InfiniteTable tableId={activePageId} data={tableData} columns={columns} />
                </div>
            </div>
        </div>
    );
};

export default ExportPageTemplate;
