import { useDispatch, useSelector } from "react-redux";
import MigratePageTemplate from "../../features/Migrate/components/templates/MigratePageTemplate";
import { useSessionContext } from "../../features/Session/Session";
import { fetchDocuments } from "../../features/Export/utils/fetchDocuments";

const collectionIds = [
    "employees",
    "roles",
    "employeeAssessments",
    "assessments",
    "traitMap",
    "boardMap",
    "talentAreas",
    "traits",
    "talentBoards",
    "actions",
];

const MigratePage = () => {
    const dispatch = useDispatch();
    const businessUnit = useSelector((state) => state.businessUnit);
    const workspace = useSelector((state) => state.workspace);
    const { workspaceRef } = useSessionContext();

    const handleFetch = () => {
        fetchDocuments(workspaceRef, collectionIds, dispatch);
    };

    return (
        <div className="page page-padding">
            <MigratePageTemplate
                businessUnit={businessUnit}
                workspace={workspace}
                onFetch={handleFetch}
            />
        </div>
    );
};

export default MigratePage;
