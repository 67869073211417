import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workspaces: [],
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        SET_WORKSPACES(state, action) {
            state.workspaces = action.payload;
        },
        RESET: () => initialState,
    },
});

const { actions, reducer } = appSlice;

export const { SET_ACTIVE_WORKSPACE_ID, SET_WORKSPACES, RESET } = actions;

export default reducer;
