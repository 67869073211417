import { collection, collectionGroup, doc, writeBatch } from "firebase/firestore";
import { chunk } from "lodash";
import applogger from "../../common/utils/applogger";
import { db } from "../firebase";

export function getWorkspaceInfoRef() {
    return doc(db, "metaData","workspaceInfo");
}

export function getUsersCollectionRef() {
    return collection(db, "users");
}

export function getWorkspacesCollectionRef() {
    return collection(db, "workspaces");
}

export function getCollectionRef(workspaceId, subCollection) {
    return collection(db, "workspaces", workspaceId, subCollection);
}

export function getSubCollectionRef(ref, subCollection) {
    return collection(ref, subCollection);
}

export function getCollectionGroupRef(collectionName) {
    return collectionGroup(db, collectionName);
}

export function getDocRef(collectionRef, docId) {
    if (docId) {
        return doc(collectionRef, docId);
    } else {
        return doc(collectionRef);
    }
}
