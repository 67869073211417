import classNames from "classnames";
import { groupBy } from "lodash";
import { PropTypes } from "prop-types";
import React, { useMemo, useState } from "react";
import ReactSelect from "react-select";

const Select = ({
    options = [],
    selectedIds = [],
    maxMenuHeight = 320,
    placeholder = "Select a field",
    menuPlacement = "auto",
    groupByField,
    className,
    isMulti,
    multiGroupFieldId,
    isClearable,
    disabled,
    onBlur = () => {},
    onChange = () => {},
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const preppedOptions = useMemo(() => {
        const newOptions = options.map((option) => {
            return { ...option };
        });
        if (groupByField) {
            // Ensure there is a valid value for groupByField
            newOptions.forEach((option) => {
                const groupByValue = option[groupByField] || option.category || "Options";
                option[groupByField] = groupByValue;
            });

            const groupsObject = groupBy(newOptions, groupByField);
            return Object.entries(groupsObject).map(([groupName, options]) => {
                const label = groupName === "undefined" ? "Options" : groupName;
                return {
                    value: label,
                    label: label,
                    options: options.map((option) => ({ ...option, value: option.id })),
                };
            });
        } else {
            return newOptions.map((option) => ({ ...option, value: option.id }));
        }
    }, [options, groupByField]);

    const availableOptions = useMemo(() => {
        if (!selectedIds || selectedIds.length === 0) return preppedOptions;
        if (!multiGroupFieldId) return preppedOptions;
        const firstSelectedId = selectedIds[0];
        const firstSelectedOption = options.find((option) => option.id === firstSelectedId);
        const filterGroupField = firstSelectedOption[multiGroupFieldId];

        // If the first selected field doesn't have a filter group, allow no other selections
        if (!filterGroupField) return [];

        // If the first selected field has a filter group, find other options with that group
        return options.filter((option) => option[multiGroupFieldId] === filterGroupField);
    }, [options, selectedIds, multiGroupFieldId, preppedOptions]);

    const value = useMemo(() => {
        if (selectedIds) {
            const ensureArray = Array.isArray(selectedIds) ? selectedIds : [selectedIds];
            if (groupByField) {
                return ensureArray.map((id) => {
                    return preppedOptions.flatMap((group) => group.options || []).find((option) => option.id === id);
                });
            } else {
                return ensureArray.map((id) => preppedOptions.find((option) => option.id === id));
            }
        } else {
            return null;
        }
    }, [preppedOptions, selectedIds, groupByField]);

    const handleChange = (selected) => {
        if (isMulti) {
            onChange(selected.map((option) => option.id));
        } else {
            onChange(selected?.id);
        }
    };

    const handleMenuOpen = () => {
        setMenuIsOpen(true);
    };

    const handleMenuClose = () => {
        setMenuIsOpen(false);
    };

    return (
        <ReactSelect
            isDisabled={disabled}
            isClearable={isClearable}
            isMulti={isMulti}
            menuPlacement={menuPlacement}
            className={classNames("text-sm", className)}
            menuShouldScrollIntoView={true}
            maxMenuHeight={maxMenuHeight}
            type="select"
            onMenuOpen={() => handleMenuOpen(true)}
            onMenuClose={() => handleMenuClose(false)}
            onChange={handleChange}
            onBlur={onBlur}
            menuIsOpen={menuIsOpen}
            options={availableOptions}
            value={value}
            placeholder={placeholder}
        />
    );
};

Select.propTypes = {
    options: PropTypes.array,
    groupByField: PropTypes.string,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    placeholder: PropTypes.string,
    maxMenuHeight: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export default Select;
