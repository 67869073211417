import UsersPageTemplate from "../../features/Users/components/templates/UsersPageTemplate";

const UsersPage = () => {
    return (
        <div className="page page-padding">
            <UsersPageTemplate />
        </div>
    );
}

export default UsersPage;