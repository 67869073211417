import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import userReducer from "./userSlice";
import workspaceReducer from "./workspaceSlice";
import businessUnitReducer from "./businessUnitSlice";

import { RESET as resetApp } from "./appSlice";
import { RESET as resetUser } from "./userSlice";
import { RESET as resetWorkspace } from "./workspaceSlice";
import { RESET as resetBusinessUnit } from "./businessUnitSlice";

const resetMiddleware = (store) => (next) => (action) => {
    if (action.type === "RESET_ALL") {
        store.dispatch(resetApp());
        store.dispatch(resetUser());
        store.dispatch(resetWorkspace());
        store.dispatch(resetBusinessUnit());
    }
    return next(action);
};

const store = configureStore({
    reducer: {
        app: appReducer,
        user: userReducer,
        workspace: workspaceReducer,
        businessUnit: businessUnitReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(resetMiddleware),
});

export default store;
