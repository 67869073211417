import { useMemo, useState } from "react";

export function useToggledList(optionIds = [], checkedIds = []) {
    const [toggled, setToggled] = useState(checkedIds);

    const toggle = (id) => {
        return setToggled((prev) => {
            if (prev.includes(id)) {
                return prev.filter((i) => i !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const toggleAll = () => {
        if (toggled.length < optionIds.length) {
            setToggled(optionIds);
        } else {
            setToggled([]);
        }
    };

    const allChecked = useMemo(() => {
        return toggled.length === optionIds.length;
    }, [optionIds, toggled]);

    return [toggled, toggle, toggleAll, allChecked];
}
