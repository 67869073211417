import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { formatName } from "../../../common/utils/stringUtils";
import { auth } from "../../../firebase/firebase";
import { useListener } from "../../../firebase/hooks/useListener";
import { getDocRef, getUsersCollectionRef } from "../../../firebase/refs/firebaseRefs";
import { RESET as RESET_USER, SET_USER } from "../../../redux/userSlice";

export function useUserListener(uid) {
    const dispatch = useDispatch();

    const userRef = useMemo(() => {
        if (!uid) return null;
        const usersRef = getUsersCollectionRef();
        const userRef = getDocRef(usersRef, uid);
        return userRef;
    }, [uid]);

    const shouldListen = useMemo(() => {
        return !!uid;
    }, [uid]);

    const handleUserDataReceived = useCallback(
        async (userDoc) => {
            try {
                const currentUser = auth.currentUser;
                if (currentUser) {
                    await currentUser.getIdToken(true);
                    let userData = { id: userDoc.id, ...userDoc.data(), displayName: formatName(userDoc.data()) };
                    let preppedUserData = ensureSerializable(userData);
                    dispatch(SET_USER(preppedUserData));
                }
            } catch (error) {
                dispatch(RESET_USER());
                throw new Error(`Error getting user data: ${error.message}`);
            }
        },
        [dispatch]
    );

    return useListener(userRef, handleUserDataReceived, shouldListen);
}
