import React, { useMemo } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { groupBy } from "lodash";
import { camelCaseToTitleCase } from "../../../../utils/stringUtils";
import { MyMenuGroup, MyMenuItem } from "../../Menu/Menu";
import MyDropdown from "../../Dropdown/Dropdown";

const InfiniteTableRowMenu = ({ row, actions, hidden }) => {
    // group orgActions by groupId
    const groupedActions = useMemo(() => {
        return groupBy(actions, "groupId");
    }, [actions]);

    if (hidden) return null;

    return (
        <MyDropdown toggleIcon={FaEllipsisV} toggleShape="circle" toggleVariant="outline" toggleSize="xs">
            {Object.entries(groupedActions).map(([groupId, actions]) => (
                <MyMenuGroup key={`action-group-${groupId}`} vertical paddingClass="py-2 px-3">
                    <MyMenuItem header>{camelCaseToTitleCase(groupId)}</MyMenuItem>
                    {actions.map((item) => {
                        const key = `${row.id}-${item.id}-org-table`;
                        return (
                            <MyMenuItem key={key} onClick={item.onClick} disabled={item.disabled}>
                                {item.label}
                            </MyMenuItem>
                        );
                    })}
                </MyMenuGroup>
            ))}
        </MyDropdown>
    );
};

export default InfiniteTableRowMenu;
