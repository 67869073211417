// Firebase stuff
import { getApp, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import applogger from "../common/utils/applogger";

const IS_DEV = process.env.NODE_ENV === "development";
const IS_TEST = process.env.NODE_ENV === "test";
const IS_LOCAL_HOST = window.location.hostname === "localhost";
const IS_STAGING = window.location.hostname.includes("staging-admin.dothings.io");
export const usingEmulators = IS_DEV || IS_TEST || IS_LOCAL_HOST;

// Live production project
const prodConfig = {
    apiKey: "AIzaSyCk4UxmtzurxvYBUQ_DAbXd8s-BzqW43xI",
    authDomain: "dothings-production.firebaseapp.com",
    projectId: "dothings-production",
    storageBucket: "dothings-production.appspot.com",
    messagingSenderId: "284826806002",
    appId: "1:284826806002:web:fd6aeb50ca5d620b07526b",
};

// Test project
const stagingConfig = {
    apiKey: "AIzaSyD_yX1mksXDFvb-GHusTkoniCkX9bUvHR4",
    authDomain: "dothings-staging-app.firebaseapp.com",
    projectId: "dothings-staging-app",
    storageBucket: "dothings-staging-app.appspot.com",
    messagingSenderId: "700807384200",
    appId: "1:700807384200:web:41208f413844ba9cd1633d",
};

/*
// Talent Mapper Version
const firebaseConfig = {
    apiKey: "AIzaSyA7vpBmdmMHy9NFMec66fo1DOFfmmxQazs",
    authDomain: "talent-mapper-app.firebaseapp.com",
    projectId: "talent-mapper-app",
    storageBucket: "talent-mapper-app.appspot.com",
    messagingSenderId: "85520720073",
    appId: "1:85520720073:web:388961ad941f2dbe6d6d18",
    measurementId: "G-V8RD6DJ37W",
};
*/

const useStagingConfig = usingEmulators || IS_STAGING;
const firebaseConfig = useStagingConfig ? stagingConfig : prodConfig;
export const projectId = firebaseConfig?.projectId;

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Initialize firebase instance
const app = getApp();
export const db = getFirestore();
export const functions = getFunctions(app, "europe-west2");
export const auth = getAuth();
export const storage = getStorage();

// Use emulators if in Development
if (usingEmulators) {
    console.log("Using empulators", `projectId: ${firebaseConfig.projectId}`);
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectStorageEmulator(storage, "localhost", 9199);
} else {
    applogger.warn("Using production project");
}
