import classNames from "classnames";
import React, { useMemo } from "react";
import {
    MdFileDownload as ExportIcon,
    MdHome as HomeIcon,
    MdOutlineFileUpload as ImportIcon,
    MdAutoFixNormal as ScriptIcon,
    MdPerson as UsersIcon,
    MdSwapHoriz as MigrateIcon,
    MdOutlineLogin as LoginIcon,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../features/Session/Session";

const items = [
    { id: "mimic", label: "Login In As", icon: LoginIcon },
    { id: "home", label: "Home", icon: HomeIcon },
    { id: "import", label: "Import", icon: ImportIcon },
    { id: "export", label: "Export", icon: ExportIcon },
    { id: "users", label: "Users", icon: UsersIcon },
    { id: "scripts", label: "Scripts", icon: ScriptIcon },
    { id: "migrate", label: "Migration", icon: MigrateIcon, emulatorsOnly: true },
];

const MenuItem = ({ item, disabled, onClick }) => {
    const { icon, label, id } = item;
    const Icon = icon;

    const handleClick = () => {
        if (disabled) return;
        onClick(id);
    };

    return (
        <li className="flex items-center justify-center">
            <button
                onClick={handleClick}
                disabled={disabled}
                className={classNames("btn btn-sm btn-circle btn-ghost", { disabled })}
            >
                <span className="tooltip tooltip-right" data-tip={label}>
                    <Icon size={24} />
                </span>
            </button>
        </li>
    );
};

const AppSideBar = ({ usingLive }) => {
    const navigate = useNavigate();
    const { checkDisabled } = useSessionContext();

    const enabledItems = useMemo(() => {
        return items.filter((item) => !item.emulatorsOnly || !usingLive);
    }, [usingLive]);

    const handleClick = (path) => {
        navigate(path);
    };

    const colorClass = usingLive ? "bg-error text-white" : "bg-base-200";

    return (
        <div className={classNames("h-full", colorClass)}>
            <ul className="menu space-y-2">
                {enabledItems.map((item) => {
                    const disabled = checkDisabled(item.id);
                    return <MenuItem key={item.id} item={item} onClick={handleClick} disabled={disabled} />;
                })}
            </ul>
        </div>
    );
};

export default AppSideBar;
