import { cloneDeep } from "lodash";
import { removeUndefined } from "../../../common/utils/objectUtils";

function migrateTalentMap(workspace) {
    const assessmentSettings = workspace?.assessmentSettings;
    const { gridThresholds, talentMapConfig: prevConfig } = assessmentSettings || {};
    const talentMapConfig = cloneDeep(prevConfig);
    const { grid: prevGrid } = talentMapConfig;
    const newGrid = [];
    for (let [col, info] of Object.entries(prevGrid || {})) {
        for (let [row, value] of Object.entries(info || {})) {
            const newInfo = {
                col: parseInt(col),
                row: parseInt(row),
                ...value,
            };
            newGrid.push(newInfo);
        }
    }
    const updateThese = ["x", "y", "z"];
    for (let key of updateThese) {
        if (talentMapConfig[key]?.fieldId === "overall") {
            talentMapConfig[key].fieldId = "overallRating";
        }
    }

    const newConfig = { ...talentMapConfig, gridThresholds, grid: newGrid };
    removeUndefined(newConfig);
    return newConfig;
}

function migrateCustomFields(workspace) {
    function shouldIMigrate(field) {
        if (field.id === "f3") return false;
        return true;
    }
    function migrateCategory(prev) {
        switch (prev) {
            case "assessment":
                return "flagFields";
            default:
                return prev;
        }
    }
    function migrateType(prev) {
        switch (prev) {
            case "picker":
                return "select";
            case "switch":
                return "binary";
            default:
                return prev;
        }
    }
    function migrateOptions(options, fieldType) {
        if (options) {
            const cleanOptions = options.map((option) => option.label);
            return cleanOptions;
        } else if (fieldType === "switch") {
            return ["Yes", "No"];
        } else {
            return [];
        }
    }

    const customFields = workspace?.customFields;
    const migrated = [];
    for (let field of customFields) {
        if (shouldIMigrate(field)) {
            const { category, type, options, label, fieldInfo } = field;
            const updatedField = {
                id: field.id,
                fieldCategory: migrateCategory(category),
                fieldType: migrateType(type),
                label: label,
                help: fieldInfo,
                options: migrateOptions(options, type),
            };
            removeUndefined(updatedField);
            migrated.push(updatedField);
        }
    }
    return migrated;
}

export function migrateConfig(workspace) {
    const defaultMap = migrateTalentMap(workspace.workspace);
    const customFields = migrateCustomFields(workspace.workspace);
    const talentMaps = {
        "5BoxGrid": defaultMap,
    };
    const updatedWorkspace = {
        ...workspace.workspace,
        talentMaps,
        customFields,
    };
    return {
        ...workspace,
        workspace: updatedWorkspace,
    };
}
