import React from "react";
import classNames from "classnames";

const Loading = ({ size = "sm", message, className = "flex-1 flex flex-col justify-center align-center" }) => {
    return (
        <div className={className}>
            {message && <span className={classNames(`text-${size}`)}>{message}</span>}
            <span className={classNames("loading loading-spinner", `loading-${size}`)} />
        </div>
    );
};

export default Loading;
