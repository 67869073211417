import React from "react";
import Checkbox from "../Checkbox/Checkbox";
import classNames from "classnames";

const CheckOrSelect = ({ option, checked, selected, onSelect, onCheck }) => {
    const handleSelect = () => {
        onSelect(option.id);
    };
    const handleCheck = () => {
        onCheck(option.id);
    };
    return (
        <div className={classNames("flex items-center p-1", { "bg-primary text-white": selected })} key={option.id}>
            <div className="w-12">
                <Checkbox checked={checked} onChange={handleCheck} />
            </div>
            <div className={classNames("flex-1", { "bg-primary text-white": selected })} onClick={handleSelect}>
                {option.label}
            </div>
        </div>
    );
};

export default CheckOrSelect;
