import { toast } from "react-toastify";
import Modal from "../../../common/components/organisms/Modal/Modal";
import { createWorkspace } from "../api/workspaceApi";
import { useEditedWorkspace } from "../hooks/useEditedWorkspace";

const CreateWorkspaceModal = ({ isOpen, onToggle }) => {
    const [editedWorkspace, updateWorkspace, isValid] = useEditedWorkspace({});

    const handleUpdateField = (key, value) => {
        updateWorkspace(key, value);
    };

    const handleSubmit = async () => {
        const result = await createWorkspace(editedWorkspace);
        if (result) {
            toast.success("Workspace created successfully");
        }
        onToggle();
    };

    return (
        <Modal title="Create Workspace" isOpen={isOpen} isValid={isValid} onToggle={onToggle} onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-4">
                <input
                    type="text"
                    className="input input-bordered"
                    value={editedWorkspace?.workspaceId || ""}
                    onChange={(e) => handleUpdateField("workspaceId", e.target.value)}
                    placeholder="Workspace ID"
                />
                <input
                    type="text"
                    className="input input-bordered"
                    value={editedWorkspace?.workspaceName || ""}
                    onChange={(e) => handleUpdateField("workspaceName", e.target.value)}
                    placeholder="Workspace Name"
                />
            </div>
        </Modal>
    );
};

export default CreateWorkspaceModal;
