import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const Tabs = ({ tabs = [], tabKey, disableTabs, onChange = () => {} }) => {
    // If tabKey is an object, use the id
    const activeKey = typeof tabKey === "object" ? tabKey.id : tabKey;

    const handleChange = (tabKey) => {
        const tab = tabs.find((tab) => tab.id === tabKey);
        if (tab?.disabled) return;
        onChange(tabKey);
    };

    return (
        <div role="tablist" className="tabs tabs-bordered w-fit">
            {tabs.map((tab) => {
                const { id, label, disabled } = tab;
                const isActive = activeKey === id;
                return (
                    <div
                        role="tab"
                        className={classNames("tab w-fit", { "tab-active": isActive })}
                        key={`tab-${id}`}
                        value={id}
                        disabled={disabled || disableTabs}
                        onClick={() => handleChange(id)}
                    >
                        <span className="tracking-tighter">{label}</span>
                    </div>
                );
            })}
        </div>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.array,
    disableTabs: PropTypes.bool,
    tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
};

export default Tabs;
