import ScriptsPageTemplate from "../../features/Scripts/components/templates/ScriptsPageTemplate";

const ScriptsPage = () => {
    return (
        <div className="page page-padding">
            <ScriptsPageTemplate />
        </div>
    );
}

export default ScriptsPage;