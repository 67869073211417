import ImportPageTemplate from "../../features/Import/components/templates/ImportPageTemplate";

const ImportPage = () => {
    return (
        <div className="page page-padding">
            <ImportPageTemplate />
        </div>
    );
}

export default ImportPage;