import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
const rootRoleId = "eLKBas";
const ownerEmployeeId = "pUrmk";

const WORKSPACE_TEMPLATE = {
    ownerId: ownerEmployeeId,
    rootRoleId: rootRoleId,
    active: true,
    configured: false,
};

async function createWorkspace(values) {
    const { workspaceId, ...workspace } = values;
    const newWorkspace = {
        ...WORKSPACE_TEMPLATE,
        ...workspace,
        configured: true,
    };
    const workspacesRef = collection(db, "workspaces");

    if (workspaceId) {
        const workspaceRef = doc(workspacesRef, workspaceId);
        try {
            await setDoc(workspaceRef, newWorkspace);
            return true;
        } catch (error) {
            console.error("Error creating workspace: ", error);
            throw error;
        }
    } else {
        try {
            await addDoc(workspacesRef, newWorkspace);
            return true;
        } catch (error) {
            console.error("Error creating workspace: ", error);
            throw error;
        }
    }
}

export { createWorkspace };
