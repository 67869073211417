import classNames from "classnames";
import React from "react";
import { useSessionContext } from "../features/Session/Session";
import { usingEmulators } from "../firebase/firebase";
import { FaEllipsisV, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import Button from "../common/components/atoms/Button/Button";

const AppToolbar = ({ onToggleCreate }) => {
    const colorClass = !usingEmulators ? "error" : "secondary";
    const { signOut } = useSessionContext();
    const { workspace } = useSelector((state) => state.workspace);
    const connectedTo = workspace?.workspaceName || "No Active Workspace";

    const handleSignOut = () => {
        signOut();
    };

    return (
        <div className={classNames("navbar bg-base-100 px-8 border-b", `border-${colorClass}`)}>
            <div className="flex-1">
                <h4>DoThings Admin</h4>
            </div>
            <div className="flex-none gap-2">
                <div className="flex divide-x">
                    <div className="px-2">
                        <Button size="sm" color="ghost" onClick={onToggleCreate}>
                            <FaPlus />
                            Create Workspace
                        </Button>
                    </div>
                    <div className="flex space-x-1 text-sm tracking-tight px-4 items-center">
                        <span>Source:</span>
                        <span>{usingEmulators ? "Emulators" : "Live"}</span>
                    </div>
                    <div className="flex space-x-1 text-sm tracking-tight px-4 items-center">
                        <span>Connected to:</span>
                        <span>{connectedTo}</span>
                    </div>
                </div>
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn btn-sm btn-circle btn-ghost">
                        <div className="w-fit">
                            <FaEllipsisV />
                        </div>
                    </div>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm border dropdown-content space-y-2 bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
                    >
                        <li>
                            <button onClick={handleSignOut}>Sign Out</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AppToolbar;
