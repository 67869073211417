import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    businessUnitId: null,
    employees: {},
    roles: {},
    assessments: {},
    employeeAssessments: {},
    boardMap: {},
    parentMap: {},
};

const businessUnitSlice = createSlice({
    name: "businessUnit",
    initialState,
    reducers: {
        SET_BUSINESS_UNIT_ID(state, action) {
            state.businessUnitId = action.payload;
        },
        SET_EMPLOYEES(state, action) {
            state.employees = action.payload;
        },
        SET_ROLES(state, action) {
            state.roles = action.payload;
        },
        SET_ASSESSMENTS(state, action) {
            state.assessments = action.payload;
        },
        SET_LEGACY_ASSESSMENTS(state, action) {
            state.employeeAssessments = action.payload;
        },
        SET_BOARD_MAP(state, action) {
            state.boardMap = action.payload;
        },
        SET_PARENT_MAP(state, action) {
            state.parentMap = action.payload;
        },
        RESET: () => initialState,
    },
});

const { actions, reducer } = businessUnitSlice;

export const {
    SET_BUSINESS_UNIT_ID,
    SET_EMPLOYEES,
    SET_ROLES,
    SET_ASSESSMENTS,
    SET_LEGACY_ASSESSMENTS,
    SET_BOARD_MAP,
    SET_PARENT_MAP,
    RESET,
} = actions;

export default reducer;
