import { sortArrayOfObjects } from "../../../common/utils/arrayUtils";
import { removeUndefined } from "../../../common/utils/objectUtils";
import { filterEnabled } from "./filterEnabled";

export function getLatestAssessment(assessments, employeeId) {
    const employeeAssessments = assessments.filter((assessment) => assessment.employeeId === employeeId);
    const sortedByAssessmentDate = sortArrayOfObjects(employeeAssessments, "assessmentDate", "desc");
    return sortedByAssessmentDate[0];
}

function migrateAssessment(assessment) {
    const flags = {
        f1: assessment.f1 ? 1 : 0,
        f2: assessment.f2,
        f4: assessment.f4 ? 1 : 0,
    };
    removeUndefined(flags);
    const migrated = {
        ratings: assessment.ratings || {},
        flags: flags,
        updatedOn: assessment.assessmentDate,
        relationship: "manager",
        reviewerId: assessment.assessedBy,
        employeeId: assessment.employeeId,
    };
    removeUndefined(migrated);
    return migrated;
}

export function migrateAssessments(businessUnit, employees) {
    const enabledEmployees = filterEnabled(employees);
    const { employeeAssessments } = businessUnit;
    const enabledEmployeeIds = filterEnabled(enabledEmployees).map((employee) => employee.id);
    const validAssessments = Object.values(employeeAssessments).filter((assessment) => !assessment.skipped);
    const allEmployeeIds = validAssessments.map((assessment) => assessment.employeeId);
    const uniqueEmployeeIds = [...new Set(allEmployeeIds)];
    const validEmployeeIds = uniqueEmployeeIds.filter((id) => enabledEmployeeIds.includes(id));
    let assessments = {};
    for (let employeeId of validEmployeeIds) {
        const latestAssessment = getLatestAssessment(validAssessments, employeeId);
        const cleanAssessment = migrateAssessment(latestAssessment);
        assessments[employeeId] = cleanAssessment;
    }
    return assessments;
}
