import { useMemo } from "react";

export function usePreppedData(tableName, workspace, businessUnit) {
    const data = useMemo(() => {
        function getData() {
            switch (tableName) {
                case "assessmentSettings":
                    return workspace?.workspace?.assessmentSettings;
                case "customFields":
                    return workspace?.workspace?.customFields;
                case "missingEmails":
                    return businessUnit?.employees;
                default:
                    return pageOptions[tableName];
            }
        }

        const pageOptions = { ...businessUnit, ...workspace };
        const rawData = getData();
        if (!rawData) return [];
        switch (tableName) {
            case "missingEmails":
                return Object.entries(rawData)
                    .filter(([_, value]) => !value.email || value.email === "")
                    .map(([key, value]) => {
                        return { id: key, ...value };
                    });
            case "parentMap":
                return Object.entries(rawData).map(([key, value]) => {
                    return { roleId: key, parentRoleId: value };
                });
            case "boardMap":
                return Object.entries(rawData).map(([key, value]) => {
                    return { boardId: key, roleIds: value };
                });
            case "snapshotDocs":
                return Object.entries(rawData).map(([key, value]) => {
                    return { boardId: key, employeeIds: value };
                });
            case "assessmentSettings":
            case "workspace":
                return Object.entries(rawData).map(([key, value]) => {
                    return { id: key, value };
                });
            case "customFields":
                return rawData;
            default:
                return Object.entries(rawData).map(([key, value]) => {
                    return { id: key, ...value };
                });
        }
    }, [tableName, businessUnit, workspace]);

    return data;
}
