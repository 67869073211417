import _ from "lodash";
import React, { useMemo, useState } from "react";
import Button from "../../../../common/components/atoms/Button/Button";
import Checkbox from "../../../../common/components/atoms/Checkbox/Checkbox";
import { useWorkspace } from "../../hooks/useWorkspace";

const FeatureFlipping = ({ workspaceConfig }) => {
    const { featureFlips } = workspaceConfig;
    const [newFeatureFlips, setNewFeatureFlips] = useState(featureFlips);
    const { flipFeatures } = useWorkspace();

    const disableSave = useMemo(() => {
        return _.isEqual(newFeatureFlips, featureFlips);
    }, [newFeatureFlips, featureFlips]);

    const handleFlip = (featureId) => {
        const updatedFlips = { ...newFeatureFlips, [featureId]: !newFeatureFlips[featureId] };
        setNewFeatureFlips(updatedFlips);
    };

    const handleSave = () => {
        flipFeatures(newFeatureFlips);
    };

    const featureList = Object.keys(featureFlips || {}).sort();

    function camelCaseToTitleCase(str) {
        return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
            return str.toUpperCase();
        });
    }

    return (
        <div className="space-y-3">
            <span className="mb-2 font-bold">Feature Flipping</span>
            <div className="flex items-center justify-between">
                <div className="space-y-2">
                    {featureList.map((featureId) => (
                        <Checkbox
                            reverse
                            checked={newFeatureFlips[featureId]}
                            name={featureId}
                            label={camelCaseToTitleCase(featureId)}
                            key={featureId}
                            onChange={() => handleFlip(featureId)}
                        />
                    ))}
                </div>
                <Button onClick={handleSave} color="primary" disabled={disableSave}>
                    Save Flips
                </Button>
            </div>
        </div>
    );
};

export default FeatureFlipping;
