import applogger from "./applogger";
import { identifyFirestoreRef } from "../../firebase/utils/identifyFirestoreRef";

let activeListenerCount = 0;
let readCount = 0;
let writeCount = 0;
let listenerCounts = {};

const TRACK_READS = true;
const TRACK_WRITES = true;


// Keeps track of the total document reads
export const readTracker = async (firestoreRef, increment = 1) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    readCount += increment;
    if (TRACK_READS) {
        applogger.info(`READ ::: ${trackerId} - ${increment} ::: TOTAL READS: (${readCount})`);
    }
};

export const writeTracker = async (firestoreRef, increment = 1) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    writeCount += increment;
    if (TRACK_WRITES) {
        applogger.info(trackerId, `WRITE ::: ${increment} ::: TOTAL WRITES: (${writeCount})`);
    }
};

// Keeps track of when a global listerer is setup and warns if it's done twice
export const listenerTracker = async (firestoreRef) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    let count = listenerCounts[trackerId] || 0;
    count++;
    listenerCounts[trackerId] = count;
    activeListenerCount++;
    if (count > 10) {
        applogger.info(`WARNING: ${trackerId} has been setup ${count} times`);
    }
    applogger.info(`LISTENING ::: ${trackerId} ::: TOTAL LISTENERS: (${activeListenerCount})`);
};

export const dropListener = (firestoreRef) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    activeListenerCount -= 1;
    applogger.info(`DROPPED LISTENER ::: ${trackerId} ::: TOTAL LISTENERS: (${activeListenerCount})`);
};
