import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

export const MyMenuItem = ({ children, header, title, linkTo, divider, hidden, disabled, onClick }) => {
    if (hidden) return null;

    const handleClick = () => {
        if (disabled || !onClick) return;
        if (onClick) onClick();
    };

    function getClassName() {
        if (title)
            return "text-left text-base-800 border-b uppercase w-full no-marker mb-5 text-lg font-bold hover:bg-base-100";
        if (header) return "text-left uppercase w-full no-marker font-semibold hover:bg-base-100";
        if (divider) return "flex items-center h-10";
        if (linkTo) return "text-sm text-left w-full font-medium no-marker";
        if (onClick) return "text-sm py-2 text-left w-full font-medium no-marker cursor-pointer hover:bg-base-100";
        return "text-sm text-left w-full font-medium no-marker flex justify-center items-start";
    }

    const className = getClassName();

    if (linkTo) {
        return (
            <li className={className}>
                <Link to={linkTo} className="w-full text-left">
                    {children}
                </Link>
            </li>
        );
    } else {
        return (
            <li
                className={classNames(className, {
                    disabled: disabled,
                })}
                onClick={handleClick}
            >
                {children}
            </li>
        );
    }
};

MyMenuItem.propTypes = {
    disabled: PropTypes.bool,
    header: PropTypes.bool,
    divider: PropTypes.bool,
    title: PropTypes.bool,
    dropdownItem: PropTypes.bool,
    linkTo: PropTypes.string,
    className: PropTypes.string,
    extraClasses: PropTypes.string,
    tooltip: PropTypes.string,
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
};

MyMenuItem.defaultProps = {
    className: "flex items-center",
    extraClasses: "",
    tooltipPosition: "left",
};

//////////////////////////////////////////////////////////////////////

export const MyMenuGroup = ({ vertical, disabled, size, hidden, children }) => {
    if (hidden) return null;

    // Disable every items if the menu is disabled
    const enhancedChildren = React.Children.map(children, (child) => {
        if (!child) return null;
        return React.cloneElement(child, { disabled: disabled || child.props.disabled });
    });

    return (
        <ul
            className={classNames("menu", `menu-${size}`, {
                "menu-horizontal space-x-2": !vertical,
                "space-y-1 divide-y": vertical,
            })}
        >
            {enhancedChildren}
        </ul>
    );
};
MyMenuGroup.propTypes = {
    className: PropTypes.string,
    flex: PropTypes.number,
    justify: PropTypes.string,
    vertical: PropTypes.bool,
    disabled: PropTypes.bool,
    removePadding: PropTypes.bool,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

MyMenuGroup.defaultProps = {
    justify: "start",
    size: "md",
};