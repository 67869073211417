import { browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { createContext, Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../../App";
import Loading from "../../common/components/atoms/Loading/Loading";
import applogger from "../../common/utils/applogger";
import { auth } from "../../firebase/firebase";
import SignInPage from "../../pages/sign-in/SignInPage";
import { useUserListener } from "./hooks/useUserListener";
import { useWorkspaceListener } from "./hooks/useWorkspaceListener";
import { getDocRef, getWorkspacesCollectionRef } from "../../firebase/refs/firebaseRefs";
import { RESET as RESET_WORKSPACE } from "../../redux/workspaceSlice";
import { RESET as RESET_BUSINESS_UNIT } from "../../redux/businessUnitSlice";

const SessionContext = createContext();

export function useSessionContext() {
    return useContext(SessionContext);
}

const Session = () => {
    const dispatch = useDispatch();
    const [uid, setUid] = useState(null);
    const [authStatus, setAuthStatus] = useState("not-checked");
    const [activeWorkspaceId, setActiveWorkspaceId] = useState(null);

    const workspaceRef = useMemo(() => {
        if (!activeWorkspaceId) return null;
        const workspacesRef = getWorkspacesCollectionRef();
        const workspaceRef = getDocRef(workspacesRef, activeWorkspaceId);
        return workspaceRef;
    }, [activeWorkspaceId]);

    const checkDisabled = useCallback(
        (itemId) => {
            switch (itemId) {
                case "home":
                case "mimic":
                case "import":
                    return false;
                default:
                    return !activeWorkspaceId;
            }
        },
        [activeWorkspaceId]
    );

    // listen once a UID is provided after sign in
    useUserListener(uid);
    const workspaceStatus = useWorkspaceListener(activeWorkspaceId);

    // Check the firebase auth state
    useEffect(() => {
        applogger.info("Checking auth state");
        // Listen to auth changes
        let unsubscribe;
        try {
            unsubscribe = auth.onAuthStateChanged(async (authUser) => {
                if (authUser) {
                    await authUser.getIdToken();
                    const { uid } = authUser;
                    setUid(uid);
                    setAuthStatus("signed-in");
                } else {
                    setUid(null);
                    setAuthStatus("signed-out");
                    applogger.info("no auth user");
                }
            });
        } catch (error) {
            applogger.error(error);
        }
        return () => {
            unsubscribe();
            dispatch({ type: "RESET_ALL" });
            setUid(null);
            setAuthStatus("not-checked");
        };
    }, [dispatch]);

    async function handleSignIn(email, password, onError) {
        try {
            // Set persistence to only last one session
            await setPersistence(auth, browserSessionPersistence);
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            onError(error.message);
        }
    }

    const handleSetActiveWorkspace = (workspaceId) => {
        dispatch(RESET_WORKSPACE());
        dispatch(RESET_BUSINESS_UNIT());
        setActiveWorkspaceId(workspaceId);
    };

    const authHasLoaded = authStatus !== "not-checked";
    const value = {
        workspaceRef,
        workspaceStatus,
        authHasLoaded,
        uid,
        activeWorkspaceId,
        checkDisabled,
        onSignIn: handleSignIn,
        onSetActiveWorkspace: handleSetActiveWorkspace,
    };

    if (!authHasLoaded) return <Loading />;
    return (
        <SessionContext.Provider value={value}>
            <BrowserRouter basename="/">
                <Suspense fallback={<Loading fullscreen />}>
                    <Routes>
                        <Route path="/*" element={<App />} />
                        <Route path="sign-in/*" element={<SignInPage />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </SessionContext.Provider>
    );
};

export default Session;
