function ensureSerializable(data) {
    if (!data) return data;

    // Convert dates to ISO strings
    if (data instanceof Date) {
        return data.toISOString();
    }

    // If it's a timestamp, convert it to a Date and then to an ISO string
    if (data?.seconds && data?.nanoseconds) {
        return new Date(data.seconds * 1000 + data.nanoseconds / 1000000).toISOString();
    }

    // If it's an array, recursively check its items
    if (Array.isArray(data)) {
        return data.map((item) => ensureSerializable(item));
    }

    // If it's an object, recursively check its values
    if (typeof data === "object") {
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, ensureSerializable(value)]));
    }

    // Return data as is if it's not a Date, a potential Date, an Array or an Object
    return data;
}

function removeUndefined(dataToClean) {
    if (!dataToClean) return null;
    if (Array.isArray(dataToClean)) {
        let cleaned = [];
        dataToClean.forEach((entry) => {
            if (entry !== undefined && entry !== "undefined") cleaned.push(entry);
        });
        return cleaned;
    } else {
        Object.keys(dataToClean).forEach((key) => dataToClean[key] === undefined && delete dataToClean[key]);
        return dataToClean;
    }
}

function removeNullAndUndefined(dataToClean) {
    if (!dataToClean) return null;
    if (Array.isArray(dataToClean)) {
        let cleaned = [];
        dataToClean.forEach((entry) => {
            if (entry !== undefined && entry !== "undefined" && entry !== null) cleaned.push(entry);
        });
        return cleaned;
    } else {
        Object.keys(dataToClean).forEach((key) => {
            if (dataToClean[key] === undefined || dataToClean[key] === null) delete dataToClean[key];
        });
        return dataToClean;
    }
}

export { ensureSerializable, removeUndefined, removeNullAndUndefined };
