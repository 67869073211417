import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeUser: null,
    userIsReady: false,
};

const appSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        SET_USER(state, action) {
            state.user = action.payload;
            state.userIsReady = true;
        },
        RESET: () => initialState,
    },
});

const { actions, reducer } = appSlice;

export const { SET_USER, RESET } = actions;

export default reducer;
