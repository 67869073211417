import { writeBatch } from "firebase/firestore";
import { chunk } from "lodash";
import { db } from "../firebase";

export function createBatch() {
    return writeBatch(db);
}

export function createSubmissionBatches(transactionQueue, batchSize = 400) {
    if (batchSize > 400) throw new Error("Batch size must be less than 400");
    const submissionBatches = chunk(transactionQueue, batchSize).map((group) => {
        let batch = createBatch();
        group.forEach((transaction) => {
            const { ref, data, deleteDoc, overwrite } = transaction;
            if (deleteDoc) {
                batch.delete(ref);
            } else {
                batch.set(ref, data, { merge: !overwrite });
            }
        });
        return batch;
    });
    return submissionBatches;
}

export async function submitBatches(batches) {
    try {
        if (batches.length > 0) {
            const promises = batches.map((batch) => batch.commit());
            await Promise.all(promises);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error submitting batches", error);
        return false;
    }
}

export function batchSet(docRef, data, batch = writeBatch(db)) {
    batch.set(docRef, data, { merge: true });
    return batch;
}
