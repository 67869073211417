import MimicPageTemplate from "../../features/Mimic/components/templates/MimicPageTemplate";
import { useSessionContext } from "../../features/Session/Session";

const MimicPage = () => {
    const sessionContext = useSessionContext();
    return (
        <div className="page page-padding">
            <MimicPageTemplate {...sessionContext} />
        </div>
    );
};

export default MimicPage;
