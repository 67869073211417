import { useDispatch, useSelector } from "react-redux";
import ExportPageTemplate from "../../features/Export/components/templates/ExportPageTemplate";
import { useSessionContext } from "../../features/Session/Session";
import { fetchDocuments } from "../../features/Export/utils/fetchDocuments";

const ExportPage = () => {
    const businessUnit = useSelector((state) => state.businessUnit);
    const workspace = useSelector((state) => state.workspace);
    const dispatch = useDispatch();
    const { workspaceRef } = useSessionContext();

    const handleFetch = (collectionIds) => {
        if (!collectionIds) return;
        fetchDocuments(workspaceRef, collectionIds, dispatch);
    };

    return (
        <div className="page page-padding">
            <ExportPageTemplate businessUnit={businessUnit} workspace={workspace} onFetch={handleFetch} />
        </div>
    );
};

export default ExportPage;
