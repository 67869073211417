import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const DropJSON = ({ fileName, onChange }) => {
    const [fileUploaded, setFileUploaded] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        const reader = new FileReader();
        reader.onload = () => {
            try {
                const json = JSON.parse(reader.result);
                onChange(json);
                setFileUploaded(true);
            } catch (error) {
                setFileUploaded(false);
                console.error("Error parsing JSON:", error);
            }
        };
        if (acceptedFiles.length) {
            reader.readAsText(acceptedFiles[0]);
        }
    }, [onChange]);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "application/json": [".json"],
        },
    });

    const getLabel = () => {
        if (!fileUploaded) {
            return `Drop data for ${fileName} here (JSON only), or click to select files`;
        } else {
            return "File Uploaded!";
        }
    };

    return (
        <div>
            <div {...getRootProps()} className="border-dashed border-4 border-gray-600 p-10">
                <input {...getInputProps()} />
                <p>{getLabel()}</p>
            </div>
        </div>
    );
};

export default DropJSON;
