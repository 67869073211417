import React, { useState } from "react";
import Button from "../../../../common/components/atoms/Button/Button";
import DropJSON from "../../../../common/components/organisms/Dropzone/DropJSON";
import { exportJSONToFile } from "../../../../common/components/organisms/InfiniteTable/utils/exportJSONtoFile";
import MigrateToWorkspaceModal from "../../../Migrate/containers/MigrateToWorkspaceModal";
import { useToggle } from "../../../../common/hooks/useToggle";
import { getDocRef, getWorkspacesCollectionRef } from "../../../../firebase/refs/firebaseRefs";
import { importMigrated } from "../../../Migrate/utils/writeMigration";
import { toast } from "react-toastify";

const ImportPageTemplate = () => {
    const [showImport, toggleImport] = useToggle();
    const [disabled, setDisabled] = useState(false);
    const [jsonData, setJsonData] = useState(null);

    const handleExport = () => {
        exportJSONToFile(jsonData, "export");
    };

    const handleImport = async (workspaceId) => {
        const workspacesRef = getWorkspacesCollectionRef();
        const workspaceRef = getDocRef(workspacesRef, workspaceId);
        const { org, talent, config } = jsonData;
        if (!org || !talent || !config) {
            toast.error("Invalid JSON");
        } else {
            try {
                setDisabled(true);
                toast.info("Migration Started");
                await importMigrated(workspaceRef, org, talent, config);
                toast.success("Migration Successful");
            } catch (error) {
                toast.error("Migration");
            } finally {
                toggleImport();
                setDisabled(false);
            }
        }
    };

    return (
        <div className="flex-1 flex space-x-6 items-stretch overflow-auto">
            <div className="flex-3 flex flex-col rounded-lg bg-white p-6 overflow-x-hidden space-y-2">
                <div className="py-2 px-6 bg-base-200 rounded-lg flex items-center justify-between">
                    <h4>Import</h4>
                    <div className="space-x-2 flex items-center">
                        <Button onClick={handleExport} size="sm" color="neutral">
                            View Export
                        </Button>
                        <Button onClick={toggleImport} size="sm" color="neutral">
                            Import
                        </Button>
                    </div>
                </div>
                <div className="flex-1 flex flex-col">
                    <DropJSON fileName="JSON" onChange={setJsonData} />
                </div>
            </div>
            <MigrateToWorkspaceModal
                isOpen={showImport}
                disabled={disabled}
                onToggle={toggleImport}
                onSubmit={handleImport}
            />
        </div>
    );
};

export default ImportPageTemplate;
