import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getUniqueId } from "../../../common/utils/getUniqueId";

const db = getFirestore();
const auth = getAuth();

async function createDevRequest(requestType, userEmail, domain = null, newClaims = null) {
    const authUser = auth.currentUser;
    if (!authUser) {
        throw new Error("User not authenticated");
    }

    const requestId = getUniqueId();
    const requestRef = doc(db, "adminRequests", requestId);
    const requestData = { requestType, userEmail, domain, newClaims, createdBy: authUser.uid };
    await setDoc(requestRef, requestData);
    return requestId;
}

export async function getSignInLink(userEmail, domain) {
    try {
        const result = await createDevRequest("impersonate", userEmail, domain);
        return result;
    } catch (error) {
        console.error("Error getting sign-in link:", error);
        throw error;
    }
}

export async function getClaims(userEmail) {
    try {
        const result = await createDevRequest("getClaims", userEmail);
        return result;
    } catch (error) {
        console.error("Error getting claims:", error);
        throw error;
    }
}

export async function updateClaims(userEmail, newClaims) {
    try {
        const result = await createDevRequest("updateClaims", userEmail, null, newClaims);
        return result;
    } catch (error) {
        console.error("Error updating claims:", error);
        throw error;
    }
}
