import { doc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { useListener } from "../../../firebase/hooks/useListener";
import { useCallback, useMemo } from "react";

export function useDevRequestListener(requestId, onResultReceived) {
    const requestRef = useMemo(() => {
        if (!requestId) return null;
        return doc(db, "adminRequests", requestId);
    }, [requestId]);

    const shouldListen = useMemo(() => !!requestRef, [requestRef]);

    const handleResultReceived = useCallback(
        (doc) => {
            if (!doc.exists()) return {};
            const data = doc.data();
            onResultReceived(data?.result || {});
        },
        [onResultReceived]
    );

    return useListener(requestRef, handleResultReceived, shouldListen);
}
