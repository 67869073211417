import { collection, doc, increment, setDoc } from "firebase/firestore";
import { DEFAULT_BUSINESS_UNIT_ID } from "../../../constants";
import { getDocRef, getSubCollectionRef } from "../../../firebase/refs/firebaseRefs";
import { migrateConfig } from "./migrateConfig";
import { migrateOrg } from "./migrateOrg";
import { migrateTalent } from "./migrateTalent";
import { createSubmissionBatches, submitBatches } from "../../../firebase/utils/batches";

const publicRoleFields = ["rootRole", "jobTitle", "department", "incumbentId", "level", "successorCount", "custom"];
const publicEmployeeFields = [
    "firstname",
    "surname",
    "email",
    "employeeNumber",
    "userRole",
    "inviteAccepted",
    "custom",
];
const publicTraitFields = ["traitName"];
const publicTalentAreaFields = ["talentAreaName", "description", "color", "excludeFromOverview"];
const publicActionFields = ["actionName"];
const publicTalentBoardFields = ["boardName", "customThresholds", "defaultThresholds", "auto"];

function writeTalentDocs(workspaceRef, items, publicFields, key, transactions) {
    const collectionRef = getSubCollectionRef(workspaceRef, key);
    const talentInfoRef = getSubCollectionRef(workspaceRef, "talentInfo");
    const infoDocRef = getDocRef(talentInfoRef, key);
    const infoDoc = {};

    Object.entries(items).forEach(([id, item]) => {
        const docRef = getDocRef(collectionRef, id);
        const mainDocData = {
            ...item,
            importCount: increment(1),
        };
        const unitDocData = {};
        for (let field of publicFields) {
            const value = item[field];
            if (value) {
                unitDocData[field] = value;
            }
        }
        infoDoc[id] = unitDocData;
        setDoc(docRef, mainDocData, { merge: true });
        transactions.push({
            ref: docRef,
            data: mainDocData,
        });
    });
    transactions.push({
        ref: infoDocRef,
        data: infoDoc,
    });
    return transactions;
}

function writeActionTracking(workspaceRef, actionTracking, transactions) {
    const actionsRef = getSubCollectionRef(workspaceRef, "actions");
    Object.entries(actionTracking).forEach(([actionId, unitDocs]) => {
        const actionRef = getDocRef(actionsRef, actionId);
        const trackingRef = getSubCollectionRef(actionRef, "actionTracking");
        Object.entries(unitDocs).forEach(([unitDocId, tracking]) => {
            const unitDocRef = getDocRef(trackingRef, unitDocId);
            transactions.push({
                ref: unitDocRef,
                data: tracking,
            });
        });
    });
    return transactions;
}

function writeOrgDocs(workspaceRef, items, collectionName, publicFields, transactions = []) {
    const collectionRef = getSubCollectionRef(workspaceRef, collectionName);
    const unitDocs = {};
    Object.entries(items).forEach(([id, item]) => {
        const itemRef = getDocRef(collectionRef, id);
        const mainDocData = {
            ...item,
            importCount: increment(1),
        };
        // Employee docs need the workspaceId on them so they can be queried as invites
        if (collectionName === "employees") {
            mainDocData.workspaceId = workspaceRef.id;
        }

        const unitDocData = {};
        for (let field of publicFields) {
            const value = item[field];
            if (value) {
                unitDocData[field] = value;
            }
        }
        transactions.push({
            ref: itemRef,
            data: mainDocData,
        });

        // add to the unit doc
        const unitDocId = item.unitDocId;
        if (unitDocId) {
            const currentUnitDoc = unitDocs[unitDocId] || {};
            currentUnitDoc[id] = unitDocData;
            unitDocs[unitDocId] = currentUnitDoc;
        }
    });

    const unitDocKey = `${collectionName.slice(0, -1)}Info`;
    const unitDocsRef = collection(workspaceRef, "businessUnits", DEFAULT_BUSINESS_UNIT_ID, unitDocKey);
    Object.entries(unitDocs).forEach(([unitDocId, unitDoc]) => {
        const unitDocRef = getDocRef(unitDocsRef, unitDocId);
        transactions.push({
            ref: unitDocRef,
            data: unitDoc,
        });
    });

    return transactions;
}

function writeTraitMap(workspaceRef, traitMap, transactions) {
    const traitMapRef = doc(workspaceRef, "talentInfo", "traitMap");
    transactions.push({
        ref: traitMapRef,
        data: traitMap,
        overwrite: true,
    });

    return transactions;
}

function writeBusinessUnitMaps(workspaceRef, boardMap, parentMap, transactions) {
    const mapsRef = collection(workspaceRef, "businessUnits", DEFAULT_BUSINESS_UNIT_ID, "maps");
    const parentMapRef = getDocRef(mapsRef, "parentMap");
    const boardMapRef = getDocRef(mapsRef, "boardMap");
    transactions.push({
        ref: parentMapRef,
        data: parentMap,
        overwrite: true,
    });
    transactions.push({
        ref: boardMapRef,
        data: boardMap,
        overwrite: true,
    });

    return transactions;
}

function writeConfig(workspaceRef, config, transactions) {
    const configRef = doc(workspaceRef, "config", "workspaceConfig");
    transactions.push({
        ref: configRef,
        data: config,
    });
    return transactions;
}

function writeAssessments(workspaceRef, assessments, transactions) {
    const workspaceId = workspaceRef.id;
    const employeesRef = collection(workspaceRef, "employees");
    Object.entries(assessments).forEach(([employeeId, assessment]) => {
        const { id, ...rest } = assessment;
        const assessmentsRef = doc(employeesRef, employeeId, "assessments", `mig-assessment`);
        const assessmentData = {
            ...rest,
            workspaceId,
            importCount: increment(1),
        };
        transactions.push({
            ref: assessmentsRef,
            data: assessmentData,
        });
    });
    return transactions;
}

function writeSnapshots(workspaceRef, businessUnitSnapshots, roleSnapshots, transactions) {
    const businessUnitsRef = collection(workspaceRef, "businessUnits");
    const rolesRef = collection(workspaceRef, "roles");

    function helper_writeUnitDoc(parentRef, unitDocs) {
        Object.entries(unitDocs).map(async ([unitDocId, snapshots]) => {
            const unitDocRef = doc(parentRef, unitDocId);
            transactions.push({
                ref: unitDocRef,
                data: snapshots,
            });
        });
    }

    // Write the business unit snapshots
    Object.entries(businessUnitSnapshots).forEach(([businessUnitId, unitDocs]) => {
        const buRef = collection(businessUnitsRef, businessUnitId, "assessmentSnapshots");
        helper_writeUnitDoc(buRef, unitDocs);
    });

    // Write the snapshots for each role
    Object.entries(roleSnapshots).forEach(([roleId, unitDocs]) => {
        const roleRef = collection(rolesRef, roleId, "assessmentSnapshots");
        helper_writeUnitDoc(roleRef, unitDocs);
    });

    return transactions;
}

async function migrate(workspaceRef, migratedOrg, migratedTalent, migratedConfig) {
    const { roles, employees, boardMap, parentMap, actionTracking, assessments } = migratedOrg;
    const { businessUnitSnapshots, roleSnapshots } = migratedOrg;
    const { talentAreas, traits, talentBoards, actions, traitMap } = migratedTalent;
    const workspaceConfig = migratedConfig.workspace;

    let transactions = [];

    // Write Employees
    transactions = writeOrgDocs(workspaceRef, employees, "employees", publicEmployeeFields, transactions);
    // Write Roles
    transactions = writeOrgDocs(workspaceRef, roles, "roles", publicRoleFields, transactions);
    // Write Talent Areas
    transactions = writeTalentDocs(workspaceRef, talentAreas, publicTalentAreaFields, "talentAreas", transactions);
    // Write Traits
    transactions = writeTalentDocs(workspaceRef, traits, publicTraitFields, "traits", transactions);
    // Write Talent Boards
    transactions = writeTalentDocs(workspaceRef, talentBoards, publicTalentBoardFields, "talentBoards", transactions);
    // Write Actions
    transactions = writeTalentDocs(workspaceRef, actions, publicActionFields, "actions", transactions);
    // Write Action Tracking
    transactions = writeActionTracking(workspaceRef, actionTracking, transactions);
    // Write Trait Map
    transactions = writeTraitMap(workspaceRef, traitMap, transactions);
    // Write Business Unit Maps
    transactions = writeBusinessUnitMaps(workspaceRef, boardMap, parentMap, transactions);
    // Write Workspace Config
    transactions = writeConfig(workspaceRef, workspaceConfig, transactions);
    // Write Assessments
    transactions = writeAssessments(workspaceRef, assessments, transactions);
    // Write business unit snapshot docs
    transactions = writeSnapshots(workspaceRef, businessUnitSnapshots, roleSnapshots, transactions);

    console.log(transactions.length, "transactions to write");

    const submissionBatches = createSubmissionBatches(transactions);
    await submitBatches(submissionBatches);
}

export function testMigration(workspaceRef, businessUnit, workspace) {
    const migratedOrg = migrateOrg(businessUnit, workspace.actions);
    const migratedTalent = migrateTalent(workspace);
    const migratedConfig = migrateConfig(workspace);
    return migrate(workspaceRef, migratedOrg, migratedTalent, migratedConfig);
}

export function importMigrated(workspaceRef, migratedOrg, migratedTalent, migratedConfig) {
    return migrate(workspaceRef, migratedOrg, migratedTalent, migratedConfig);
}
