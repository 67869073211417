import classNames from "classnames";
import { useState } from "react";

// Higher-Order Component
const withSubmit = (WrappedComponent) => {
    return ({ onClick, ...props }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);

        const handleClick = async () => {
            setIsSubmitting(true);
            try {
                // Ensure onClick is a function before calling it
                if (typeof onClick === "function") {
                    await onClick();
                }
            } catch (error) {
                console.error("Error during submission:", error);
            } finally {
                setIsSubmitting(false);
            }
        };

        const disabled = isSubmitting || props.disabled;

        return <WrappedComponent {...props} onClick={handleClick} loading={isSubmitting} disabled={disabled} />;
    };
};

function getTextColorClass(color) {
    switch (color) {
        case "primary":
        case "info":
        case "neutral":
            return "text-white";
        default:
            return "text-neutral";
    }
}

const Button = ({ size, color = "ghost", variant, loading, disabled, children, onClick }) => {
    const colorClass = `btn-${color}`;
    const sizeClass = `btn-${size}`;
    const variantClass = `btn-${variant}`;
    const disabledClass = disabled ? "btn-disabled" : "";
    let textColorClass = getTextColorClass(color);

    const handleClick = (e) => {
        if (disabled || loading) return;
        onClick(e);
    };

    return (
        <button
            className={classNames(
                "btn uppercase tracking-tight font-semi-bold",
                colorClass,
                textColorClass,
                sizeClass,
                variantClass,
                disabledClass
            )}
            onClick={handleClick}
        >
            {loading ? <span className={classNames("loading loading-spinner", `loading-${size}`)}></span> : children}
        </button>
    );
};

export const SubmitButton = withSubmit(Button);

export default Button;
