import classNames from "classnames";

const Checkbox = ({ size = "sm", color = "neutral", label, reverse = false, checked, onChange }) => {
    const handleChange = () => {
        onChange(!checked);
    };
    const sizeClass = `checkbox-${size}`;
    const colorClass = `checkbox-${color}`;
    const textSizeClass = `text-${size}`;
    const labelAtStart = label && !reverse;
    const labelAtEnd = label && reverse;

    function renderLabel(show) {
        if (!show) return null;
        return <label className={classNames(textSizeClass, "tracking-tight font-medium capitalize")}>{label}</label>;
    }

    return (
        <div className="flex space-x-2 items-center">
            {renderLabel(labelAtStart)}
            <input
                className={classNames("checkbox", colorClass, sizeClass, "cursor-pointer")}
                checked={checked}
                type="checkbox"
                onChange={handleChange}
            />
            {renderLabel(labelAtEnd)}
        </div>
    );
};

export default Checkbox;
