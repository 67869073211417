import React, { useState } from "react";
import Button from "../../../../common/components/atoms/Button/Button";
import { useWorkspace } from "../../hooks/useWorkspace";

const RoleLimits = ({ workspaceConfig }) => {
    const { updateRoleLimit } = useWorkspace();
    // TODO - workspace should automatically increment/decrement roleCount when roles are created/deleted
    const { roleLimit } = workspaceConfig;
    const [newRoleLimit, setNewRoleLimit] = useState(roleLimit || 50);

    const handleSaveChanges = () => {
        updateRoleLimit(newRoleLimit);
    };

    const handleChangeLimit = (e) => {
        const val = parseInt(e.target.value);
        setNewRoleLimit(val);
    };

    return (
        <div>
            <h6 className="font-bold">Role Limit</h6>
            <div className="flex items-center justify-between">
                <input
                    type="number"
                    value={newRoleLimit}
                    placeholder="Enter Role Limit"
                    className="input input-bordered w-full max-w-xs input-ms"
                    onChange={handleChangeLimit}
                />
                <Button onClick={handleSaveChanges} color="primary" disabled={newRoleLimit === roleLimit}>
                    Save Limit Change
                </Button>
            </div>
        </div>
    );
};

export default RoleLimits;
