import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { MdFileDownload } from "react-icons/md";

const InfiniteTableHeader = ({
    tableId,
    columns = [],
    showCheckboxes = false,
    hideExport = false,
    allChecked = false,
    getStyle,
    onCheckAll =() => {},
    onHeaderClick = () => {},
    onExportClick =() => {},
}) => {
    const handleHeaderClick = (col) => {
        if (col.onHeaderClick) {
            col.onHeaderClick();
        } else {
            onHeaderClick(col.id);
        }
    };

    const handleExportClick = () => {
        onExportClick();
    };

    return (
        <div className="flex bg-blue-100 overflow-visible">
            {showCheckboxes && (
                <div className="w-12 bg-blue-100 border border-solid middle">
                    <input
                        className="checkbox checkbox-primary checkbox-xs"
                        checked={allChecked}
                        type="checkbox"
                        onChange={onCheckAll}
                    />
                </div>
            )}
            {columns.map((col) => {
                const style = getStyle(col);
                const flexSize = col.size === "sm" ? 1 : col.size === "lg" ? 3 : 2;
                const flexClass = `flex-${flexSize}`;
                return (
                    <div
                        data-tip={col.tooltip || col.label}
                        data-for={tableId}
                        key={`${col.id}-header`}
                        className={classNames(
                            "px-1 py-2 clickable border text-sm tracking-tighter border-solid font-semibold whitespace-normal",
                            col.className,
                            { [flexClass]: !col.width }
                        )}
                        onClick={() => handleHeaderClick(col)}
                        style={style}
                    >
                        {col.headerRenderer ? col.headerRenderer(col) : col.label}
                    </div>
                );
            })}
            <div className="w-12 border border-solid middle" data-tip="export" data-for={tableId}>
                {!hideExport && (
                    <button className="btn btn-xs btn-circle btn-ghost" onClick={handleExportClick} tooltip="Export">
                        <MdFileDownload />
                    </button>
                )}
            </div>
        </div>
    );
};

InfiniteTableHeader.propTypes = {
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.array,
    showCheckboxes: PropTypes.bool,
    allChecked: PropTypes.bool,
    onCheckAll: PropTypes.func,
    onHeaderClick: PropTypes.func,
    getStyle: PropTypes.func,
};

export default InfiniteTableHeader;
