import { filterEnabled } from "./filterEnabled";

const AUTO_BOARD_ID = "GLb_TB";

function migrateTalentAreas(talentAreas) {
    const enabledAreas = filterEnabled(talentAreas);
    let migrated = {};
    for (let area of enabledAreas) {
        const { id, publicInfoDocId, color, ...rest } = area;
        const newArea = {
            ...rest,
            color: color || "#9856c9",
        };
        migrated[id] = newArea;
    }
    return migrated;
}

function migrateTraits(traits) {
    const enabledTraits = filterEnabled(traits);
    let migrated = {};
    for (let trait of enabledTraits) {
        const {
            id,
            talentAreaIds,
            actionIds,
            boardsOnly,
            linkedTalentBoardIds,
            managersOnly,
            publicInfoDocId,
            ...newTrait
        } = trait;
        migrated[id] = newTrait;
    }
    return migrated;
}

function migrateBoards(boards) {
    const enabledBoards = filterEnabled(boards);
    let migrated = {};
    for (let board of enabledBoards) {
        const { id, talentBoardName, publicInfoDocId, traitIds, ...rest } = board;
        const newBoard = {
            ...rest,
            boardName: talentBoardName,
        };
        if (id === "mMGmnt") {
            newBoard.auto = "managers";
        }
        migrated[id] = newBoard;
    }
    // Add the global board
    const globalBoard = {
        boardName: "Core Competencies",
        auto: "all",
    };
    migrated[AUTO_BOARD_ID] = globalBoard;
    return migrated;
}

function migrateActions(actions) {
    const enabledActions = filterEnabled(actions);
    let migrated = {};
    for (let action of enabledActions) {
        const { id, details, publicInfoDocId, scheduledEmployeeIds, completedEmployeeIds, ...rest } = action;
        const newAction = {
            ...rest,
            description: details,
        };
        migrated[id] = newAction;
    }
    return migrated;
}

function buildTraitMap(workspace) {
    const { talentAreas, traits, talentBoards, actions } = workspace;
    let traitMap = {};
    const enabledTraits = filterEnabled(traits);
    const enabledBoardIds = filterEnabled(talentBoards).map((board) => board.id);
    const enabledActionIds = filterEnabled(actions).map((action) => action.id);
    const enabledAreaIds = filterEnabled(talentAreas).map((area) => area.id);

    for (let trait of enabledTraits) {
        const { boardsOnly, managersOnly } = trait;
        let traitMapEntry = {
            linkedTalentAreaId: null,
            linkedActionIds: [],
            linkedTalentBoardIds: [],
        };
        if (trait.talentAreaIds) {
            const linkedAreaId = trait.talentAreaIds[0];
            if (enabledAreaIds.includes(linkedAreaId)) {
                traitMapEntry.linkedTalentAreaId = linkedAreaId;
            }
        }
        if (trait.actionIds) {
            for (let actionId of trait.actionIds) {
                if (enabledActionIds.includes(actionId)) {
                    traitMapEntry.linkedActionIds.push(actionId);
                }
            }
        }
        if (trait.linkedTalentBoardIds) {
            for (let boardId of trait.linkedTalentBoardIds) {
                if (enabledBoardIds.includes(boardId)) {
                    traitMapEntry.linkedTalentBoardIds.push(boardId);
                }
            }
        }
        if (!boardsOnly && !managersOnly) {
            // Add the global board
            traitMapEntry.linkedTalentBoardIds.push(AUTO_BOARD_ID);
        }
        if (managersOnly) {
            // Add the managers board
            traitMapEntry.linkedTalentBoardIds.push("mMGmnt");
        }
        traitMap[trait.id] = traitMapEntry;
    }
    return traitMap;
}

export function migrateTalent(workspace) {
    const { talentAreas, traits, talentBoards, actions } = workspace;
    const migratedTalentAreas = migrateTalentAreas(talentAreas);
    const migratedTraits = migrateTraits(traits);
    const migratedBoards = migrateBoards(talentBoards);
    const migratedActions = migrateActions(actions);
    const traitMap = buildTraitMap(workspace);
    return {
        ...workspace,
        talentAreas: migratedTalentAreas,
        traits: migratedTraits,
        talentBoards: migratedBoards,
        actions: migratedActions,
        traitMap,
    };
}
