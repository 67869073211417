import { useEffect, useState } from "react";
import Loading from "../../../../common/components/atoms/Loading/Loading";
import { getSignInLink } from "../../../Users/utils/userUtils";
import { useDevRequestListener } from "../../../DevRequests/hooks/useDevRequestListener";
import Button from "../../../../common/components/atoms/Button/Button";

const MimicPageTemplate = () => {
    const [requestId, setRequestId] = useState();
    const [result, setResult] = useState({});
    const [email, setEmail] = useState("");
    const [domain, setDomain] = useState("");
    const status = useDevRequestListener(requestId, setResult);
    const { signInLink, error } = result;

    // Allows mimic to work on either prod or emulators
    useEffect(() => {
        const activePage = window.location.href;
        const firstIndex = activePage.indexOf("/");
        setDomain(activePage.substring(0, activePage.indexOf("/", firstIndex + 2)));
    }, []);

    // Generate a sign in link for the user
    const handleMimicUser = async (email) => {
        setResult({});
        try {
            const requestId = await getSignInLink(email, domain);
            setRequestId(requestId);
        } catch (error) {
            console.error("Error calling cloud function:", error);
            setRequestId(null);
        }
    };

    const handleEmailChange = (e) => {
        setResult({});
        setEmail(e.target.value);
    }

    const handleSignIn = () => {
        // Copy email to clipboard
        navigator.clipboard.writeText(email);
        window.open(signInLink, "_blank", "noopener,noreferrer");
    };

    const handleCopyLink = () => {
        // Copy email to clipboard
        navigator.clipboard.writeText(signInLink);
    }

    if (error) throw new Error(error);

    return (
        <div className="page">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleMimicUser(email);
                }}
            >
                <input
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    className="border border-gray-300 rounded px-4 py-2"
                />
                <input
                    type="text"
                    placeholder="Enter domain"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    className="border border-gray-300 rounded px-4 py-2"
                />
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
                >
                    Mimic User
                </button>
            </form>
            {status === "hang on" && <Loading />}
            {signInLink && (
                <div className="mt-4 flex flex-col space-y-4 max-w-sm">
                    <Button color="primary" onClick={handleSignIn}>Sign in</Button>
                    <Button color="secondary" onClick={handleCopyLink}>Copy to clipboard</Button>
                </div>
            )}
        </div>
    );
};

export default MimicPageTemplate;
