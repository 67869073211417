import { getDocs } from "firebase/firestore";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { getSubCollectionRef } from "../../../firebase/refs/firebaseRefs";
import { checkIfCollectionOrQueryRef } from "../../../firebase/utils/identifyFirestoreRef";
import {
    SET_ASSESSMENTS,
    SET_BOARD_MAP,
    SET_EMPLOYEES,
    SET_LEGACY_ASSESSMENTS,
    SET_PARENT_MAP,
    SET_ROLES,
} from "../../../redux/businessUnitSlice";
import {
    SET_ACTIONS,
    SET_TALENT_AREAS,
    SET_TALENT_BOARDS,
    SET_TRAIT_MAP,
    SET_TRAITS,
} from "../../../redux/workspaceSlice";

function getRef(workspaceRef, collection) {
    switch (collection) {
        case "assessments":
            // return a collection group ref
            return null;
        default:
            // todo: add business unit filter
            return getSubCollectionRef(workspaceRef, collection);
    }
}

function getAction(collection) {
    switch (collection) {
        case "employees":
            return SET_EMPLOYEES;
        case "roles":
            return SET_ROLES;
        case "assessments":
            return SET_ASSESSMENTS;
        case "employeeAssessments":
            return SET_LEGACY_ASSESSMENTS;
        case "boardMap":
            return SET_BOARD_MAP;
        case "parentMap":
            return SET_PARENT_MAP;
        case "talentAreas":
            return SET_TALENT_AREAS;
        case "traits":
            return SET_TRAITS;
        case "talentBoards":
            return SET_TALENT_BOARDS;
        case "actions":
            return SET_ACTIONS;
        case "traitMap":
            return SET_TRAIT_MAP;
        default:
            return null;
    }
}

export async function fetchDocuments(workspaceRef, collections, dispatch) {
    for (let collection of collections) {
        const ref = getRef(workspaceRef, collection);
        const isValidLink = checkIfCollectionOrQueryRef(ref);
        if (isValidLink) {
            const docs = await getDocs(ref);
            const allData = {};
            docs.forEach((doc) => {
                const data = ensureSerializable(doc.data());
                allData[doc.id] = data;
            });
            const ACTION = getAction(collection);
            if (ACTION) {
                dispatch(ACTION(allData));
            }
        }
    }
}
