import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Session from "./features/Session/Session.jsx";
import store from "./redux/store.js";
import "./scss/style.scss";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <Session />
    </Provider>
);
