export function prepTableColumns(data) {
    const allColumns = data.reduce((acc, obj) => {
        return acc.concat(Object.keys(obj));
    }, []);
    const uniqCols = [...new Set(allColumns)];
    return uniqCols.map((col) => {
        return {
            id: col,
            label: col,
            size: "sm",
            minWidth: 220,
            cellRenderer: (row) => {
                const dataType = typeof row[col];
                switch (dataType) {
                    case "object":
                        return JSON.stringify(row[col]);
                    case "boolean":
                        return row[col] ? "Yes" : "No";
                    case "string":
                    default:
                        return row[col];
                }
            },
        };
    });
}
