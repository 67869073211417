import { getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useToggle } from "../common/hooks/useToggle";
import CreateWorkspaceModal from "../features/Workspace/containers/CreateWorkspaceModal";
import { usingEmulators } from "../firebase/firebase";
import { getWorkspaceInfoRef } from "../firebase/refs/firebaseRefs";
import { SET_WORKSPACES } from "../redux/appSlice";
import AppSideBar from "./AppSideBar";
import AppToolbar from "./AppToolbar";

const AppLayout = () => {
    const dispatch = useDispatch();
    const [showCreate, toggleCreate] = useToggle();

    // Fetch a list of the active workspaces
    useEffect(() => {
        async function fetchList() {
            const workspaceInfoRef = getWorkspaceInfoRef();
            const workspaceInfoSnap = await getDoc(workspaceInfoRef);
            const workspaces = workspaceInfoSnap.data()?.workspaces;
            if (workspaces) {
                const prepped = Object.entries(workspaces).map(([id, name]) => ({ id, name }))
                dispatch(SET_WORKSPACES(prepped))
            } else {
                dispatch(SET_WORKSPACES([]))
            }
        }
        fetchList();
    }, [dispatch]);

    return (
        <div className="fixed inset-0 flex flex-col overflow-auto">
            <AppToolbar onToggleCreate={toggleCreate} />
            <div className="flex-1 overflow-auto flex items-stretch">
                <div className="w-14 h-full bg-primary">
                    <AppSideBar usingLive={!usingEmulators} />
                </div>
                <div className="flex-1 flex flex-col overflow-auto">
                    <Outlet />
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <CreateWorkspaceModal isOpen={showCreate} onToggle={toggleCreate} />
        </div>
    );
};

export default AppLayout;
