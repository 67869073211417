import { useSelector } from "react-redux";
import HomePageTemplate from "../../features/Home/components/templates/HomePageTemplate";
import { useSessionContext } from "../../features/Session/Session";

const HomePage = () => {
    const sessionContext = useSessionContext();
    const workspaces = useSelector((state) => state.app.workspaces);
    const { workspace, workspaceConfig } = useSelector((state) => state.workspace);

    if (!workspaces) return null;
    return (
        <div className="page page-padding">
            <HomePageTemplate
                {...sessionContext}
                workspaces={workspaces}
                activeWorkspace={workspace}
                workspaceConfig={workspaceConfig}
            />
        </div>
    );
};

export default HomePage;
