export function exportJSONToFile(jsonObject, filename) {
    const jsonStr = JSON.stringify(jsonObject, null, 2); // Convert JSON object to string
    const blob = new Blob([jsonStr], { type: 'application/json' }); // Create a blob with the JSON string
    const url = URL.createObjectURL(blob); // Create a URL for the blob
  
    const a = document.createElement('a'); // Create a temporary anchor element
    a.href = url; // Set the href to the blob URL
    a.download = `${filename}.json`; // Set the download attribute with the desired file name
    document.body.appendChild(a); // Append the anchor to the document
    a.click(); // Programmatically click the anchor to trigger the download
    document.body.removeChild(a); // Remove the anchor from the document
    URL.revokeObjectURL(url); // Revoke the object URL to free up memory
  }