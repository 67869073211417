import classNames from "classnames";
import { Children } from "react";

const Each = ({ render, of, filter, emptyStateMessage, emptyStateClass }) => {
    if (!of) return null;
    if (filter) {
        return Children.toArray(
            of.filter(filter).map((item, index) => {
                return render(item, index);
            })
        );
    }
    if (of.length === 0) {
        return <div className={classNames(emptyStateClass)}>{emptyStateMessage}</div>;
    }
    return Children.toArray(
        of.map((item, index) => {
            return render(item, index);
        })
    );
};

export default Each;
